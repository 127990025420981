/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipi di elementi presenti in offerta formativa. Valori previsti: *CORSO*, *ATTIVITA_EXTRA*, *ATTIVITA_EXTRA_CUSTOM*, *ATTIVITA_TRASVERSALE*
 */
export type ElementoOffertaFormativaType = 'CORSO' | 'ATTIVITA_EXTRA' | 'ATTIVITA_EXTRA_CUSTOM' | 'ATTIVITA_TRASVERSALE';

export const ElementoOffertaFormativaType = {
    CORSO: 'CORSO' as ElementoOffertaFormativaType,
    ATTIVITAEXTRA: 'ATTIVITA_EXTRA' as ElementoOffertaFormativaType,
    ATTIVITAEXTRACUSTOM: 'ATTIVITA_EXTRA_CUSTOM' as ElementoOffertaFormativaType,
    ATTIVITATRASVERSALE: 'ATTIVITA_TRASVERSALE' as ElementoOffertaFormativaType
};