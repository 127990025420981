<ng-container *transloco="let t ">
    <app-dialog-wrap [data]="data" (close$)="dialogRef.close()">

        <ng-container *ngIf="formExist">

            <ng-container *ngIf="data?.alert">
                <fuse-alert
                    class="mx-6 my-2"
                    [type]="data?.alert?.type"
                    [appearance]="'outline'"
                >{{data?.alert?.message}}</fuse-alert>
            </ng-container>

            <app-form [formGroupConfig]="data.formConfig"
                      [validatorFns]="data.formValidator"
                      [valueForm]="data.valueForm"
                      [touched]="touched"
                      [readOnly]="data.readOnly"
            ></app-form>

            <ng-container *ngIf="data?.table && data?.table?.configuration" >
                <mat-label *ngIf="data?.table?.title">{{data?.table?.title}}</mat-label>
                <div style="border-radius: 6px;  border: 1px solid #e2e8f0;" class="mb-3 mt-2 mx-6">
                <app-generic-table  [configuration]="data?.table?.configuration">
                </app-generic-table>
                </div>
            </ng-container>

            <fuse-alert
                class="fuse-alter mt-8 "
                *ngIf="alert$ | async"
                [appearance]="'outline'"
                [type]="'error'"
                [@shake]="true"
            >
                {{alert$ | async}}
            </fuse-alert>

        </ng-container>
    </app-dialog-wrap>
    <!--Form-->


    <!-- Actions -->
    <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show || data.actions.confirm2?.show">
        <div
            class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

            <!-- Cancel -->
            <ng-container *ngIf="data.actions.cancel.show">
                <button color="accent" mat-flat-button [matDialogClose]="'cancelled'"
                        [disabled]="formComponent?.formGroup?.disabled && !data.readOnly">
                    {{data.actions.cancel.label || t('dialog.cancel')}}
                </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container *ngIf="data.actions.confirm.show && !data.readOnly">
                <button mat-flat-button
                        (click)=" saveForm()"
                        [disabled]="formComponent?.formGroup?.disabled"
                        [color]="data.actions.confirm.color">
                                    <span>
                    {{data.actions.confirm.label || t('dialog.confirm')}}
                                    </span>
                    <mat-progress-spinner
                        style="margin-left: 5px"
                        *ngIf="formComponent?.formGroup?.disabled"
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                </button>

            </ng-container>

            <ng-container *ngIf="data.actions.confirm2?.show && !data.readOnly">
                <button mat-flat-button
                        (click)="saveForm2()"
                        [disabled]="formComponent?.formGroup?.disabled"
                        [color]="data.actions.confirm2.color">
                                    <span>
                    {{data.actions.confirm2.label || t('dialog.confirm')}}
                                    </span>
                    <mat-progress-spinner
                        style="margin-left: 5px"
                        *ngIf="formComponent?.formGroup?.disabled"
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                </button>

            </ng-container>

        </div>
    </ng-container>


</ng-container>
