import { AbstractDefaultComponent } from "app/shared/abstracts/abstract-default-component/abstract-default-component";
import {Component, OnInit} from "@angular/core";
import {
    ClickEvent,
    GenericTableConfigurationModel, TipoClickEnum,
    TipoColonnaEnum
} from "app/shared/components/table/model/generic-table-model";
import {
    AttivitaExtraPianoDiStudiStatus,
    AuthorityType, OpenTicketRequestDTO, OpenTicketResponseDTO, PageTicketDTO, TicketPriority,
    TicketsService, TicketStatus, TicketType, UsersService
} from "api-clients/generated/services";
import {Model} from "survey-core";
import { AppInitService } from "app/shared/service/app-init.service";
import {Router} from "@angular/router";
import {LocalStorageService} from "../../../shared/service/local-storage.service";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {LogoutService} from "../../../shared/service/logout.service";
import {finalize, switchMap, take, takeUntil, tap} from "rxjs";
import {filter, skip} from "rxjs/operators";
import {PathEnum} from "../../../app.routing";
import {italianDateWithFormat} from "../../../shared/utils/utils-date";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormGroup} from "@angular/forms";
import {
    DialogAddTicketMessageComponent,
    DialogAddTicketMessageI
} from "../../../shared/components/dialog-add-ticket-message/dialog-add-ticket-message.component";
import {PageEvent} from "@angular/material/paginator";
import { CicloCorsoRuoloInterface } from "app/shared/interface/CicloCorsoRuoloInterface";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import fs from 'file-saver';
import {get} from "lodash";

const ptaOrder = [
    AuthorityType.DOCENTECORSO,
    AuthorityType.GRUPPODICOORDINAMENTO,
    AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO,
    AuthorityType.GRUPPOPTA
];

const docenteOrder = [
    AuthorityType.COORDINATORE,
    AuthorityType.COLLEGIODOCENTI,
    AuthorityType.GRUPPODICOORDINAMENTO,
    AuthorityType.DOCENTECORSO,
    AuthorityType.SUPERVISORE,
    AuthorityType.COSUPERVISORE
];

const esternoOrder = [
    AuthorityType.SUPERVISORE,
    AuthorityType.COSUPERVISORE,
    AuthorityType.RELATORE
];

export enum TicketTipologia {
    STANDARD = "STANDARD",
    CRITICA = "CRITICA",
    URGENTE = "URGENTE"
}

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent extends AbstractDefaultComponent implements OnInit {

    loading: any;
    protected ticketsListTableConfiguration: GenericTableConfigurationModel;
    private currentSottoruolo: AuthorityType;
    protected readonly AuthorityType = AuthorityType;
    private readonly DEFAULT_PAGE_SIZE = 10;
    private currentTriple: CicloCorsoRuoloInterface;
    private currentHighestSottoruolo: AuthorityType;
    private ticketAddedMessage: string;
    private errorAddingTicketMessage: string;
    protected readonly TicketType = TicketType;
    protected readonly TicketTipologia = TicketTipologia;
    private loadingSottoruoli: Boolean;
    private loadingTickets: Boolean;
    private isTicketsRequestShowingError: Boolean;
    private isSottoruoliRequestShowingError: boolean;

    constructor(private router: Router,
                private appInitService: AppInitService,
                private _translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private localStorageService: LocalStorageService,
                private dialog: MatDialog,
                private ticketsService: TicketsService,
                private logOutService: LogoutService,
                private usersService: UsersService,
    ) {
        super();
    }

    ngOnInit(): void {


       this._translocoService.selectTranslate('support.ticket_added_successfully').subscribe(value => this.ticketAddedMessage = value);
        this._translocoService.selectTranslate('support.error_adding_ticket').subscribe(value => this.errorAddingTicketMessage = value);
        this.getTicketsRequest();


        if(this.appInitService.isAreaAdministrator){
            this.currentTriple = {
                ciclo: this.appInitService.cicloAmmSelected?.toString(),
                ruolo: undefined,
                codiceCorsoStudi:  undefined,
                denominazioneCorsoStudi: undefined,
                codiceCorsoStudiCineca: undefined,
            }
        } else {
            // setting the current quadruple
            this.currentTriple = this.localStorageService.getCicloCorsoRuolo();
            this.currentSottoruolo = this.localStorageService.getSottoruoloCiclo();
            if(this.currentTriple?.ruolo === AuthorityType.DOCENTE || this.currentTriple?.ruolo === AuthorityType.PERSONALEPTA || this.currentTriple?.ruolo === AuthorityType.ESTERNO){
                // getting sottoruoli to compute the highest level sottoruolo
                this.getSottoruoliRequest();
            }
/*            // subscribe to the current quadruple
            this.appInitService.cicloCorsoRuoloSelected$.asObservable().pipe(
                skip(1),
                takeUntil(this.destroy$),
            ).subscribe((tripla) => {
                // getting sottoruoli to compute the highest level sottoruolo
                this.currentTriple = tripla;
                this.currentSottoruolo = tripla?.sottoruolo;
                if(this.currentTriple?.ruolo === AuthorityType.DOCENTE || this.currentTriple?.ruolo === AuthorityType.PERSONALEPTA || this.currentTriple?.ruolo === AuthorityType.ESTERNO){
                    this.getSottoruoliRequest();
                }
                this.getTicketsRequest();
            });*/
            // getting sottoruoli to compute the highest level sottoruolo
            this.currentTriple = this.localStorageService.getCicloCorsoRuolo();
            this.currentSottoruolo = this.localStorageService.getCicloCorsoRuolo()?.sottoruolo;
            if(this.currentTriple?.ruolo === AuthorityType.DOCENTE || this.currentTriple?.ruolo === AuthorityType.PERSONALEPTA || this.currentTriple?.ruolo === AuthorityType.ESTERNO){
                this.getSottoruoliRequest();
            }
            this.getTicketsRequest();
        }

    }


    tableClickAction($event: ClickEvent) {
        this.router.navigateByUrl(this.router.url + '/' + $event.value.id);
    }

    private buildTicketsTableConfiguration(pageTickets: PageTicketDTO, pageSize: number = this.DEFAULT_PAGE_SIZE): GenericTableConfigurationModel {
        let config = {
            configuration: {
                data: pageTickets?.content?.map(ticket => ({
                    dataCreazioneFormattata: ticket.formatedCreatedAt ? italianDateWithFormat(ticket.formatedCreatedAt): undefined,
                    ...ticket
                })),
                sticky: true,
                totalElements: pageTickets?.totalElements,
                messaggioDatiAssenti: 'custom_table.no_data_support',
                hidePaginator: false,

                pageSize: pageSize,
                pageSizeOptions: [10, 20, 30, 40, 50],
                isPaginatedBE: true,

                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'support.id',
                        colonnaKey: 'id',
                        flex: 10,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'support.creation_date',
                        colonnaKey: 'dataCreazioneFormattata',
                        flex: 15,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS,
                        nomeColonna: 'support.priority',
                        colonnaKey: 'priority',
                        flex: 10,
                        chipWidthAuto: true,
                        statusType: TicketPriority
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'support.subject',
                        colonnaKey: 'subject',
                        flex: 42
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS,
                        nomeColonna: 'common.status',
                        colonnaKey: 'status',
                        flex: 10,
                        chipWidthAuto: true,
                        statusType: TicketStatus
                    },
                ],
            }
        };
        let actions = {
            nomeColonna: 'support.open_ticket_page',
            colonnaKey: 'actions',
            flex: 13,
            tipo: TipoColonnaEnum.ACTION,
            button: [
                {
                    nameIconButton: 'chevron_right',
                    click: TipoClickEnum.OPEN_TICKET_DETAIL,
                    tooltip: 'support.open_ticket_page',
                    color: 'accent',
                }
            ]
        };
        config.configuration.configurazioneTabella.push(actions);
        return config;
    }

    private getTicketsRequest(isFirstTime: boolean = true, page: number = 0, pageSize: number = this.DEFAULT_PAGE_SIZE) {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        this.isTicketsRequestShowingError = false;
        this.loadingTickets = true;
        this.ticketsService.getTicketsForm(
                undefined,
                TicketType.ATENA,
                undefined,
                undefined,
                page, pageSize).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                if(!this.loadingSottoruoli) {
                    this.loading = false;
                    this.fuseConfirmationService.hideLoader();
                }
                this.loadingTickets = false;
            })
        ).subscribe({
            next: (pageTickets: PageTicketDTO) => {
                this.ticketsListTableConfiguration = this.buildTicketsTableConfiguration(pageTickets, pageSize);
            },
            error: (err) => {
                if(!this.isSottoruoliRequestShowingError) {
                    this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                        () => this.logOutService.goToHome(),
                        () => this.ngOnInit(),
                        'common.go_to_home',
                        err?.error?.message);
                }
                this.isTicketsRequestShowingError = true;
            }
        });
    }

    openAddNewTicketDialog() {
        let data: DialogAddTicketMessageI = {
            close: false,
            title: 'support.add_new_ticket',
            icon: {
                name: 'mat_outline:info',
                color: 'primary',
            },
            alsoAddTicket: true,
            isAreaAdministrator: this.appInitService.isAreaAdministrator,
            onAddPressed: (form, dialogRef) => this.addNewTicketRequest(form, dialogRef)
        }
        this.dialog.open(DialogAddTicketMessageComponent, {
            data: data,
            panelClass: ['dialog-responsive-full-screen', 'lg:w-[65vw]', 'sm:w-[80vw]'],
            hasBackdrop: true,
            disableClose: true,
        });
    }

    private addNewTicketRequest(form: FormGroup, dialogRef: MatDialogRef<any>) {
        this.fuseConfirmationService.showLoader();
        const formRawValue = form.getRawValue();
        let newTicket: OpenTicketRequestDTO = {
            oggetto: formRawValue.oggetto,
            messaggio: this.createMessage(form),
            type: TicketType.ATENA,
            idEtichetta: formRawValue.categoria
        }
        // creating message
        const htmlMessage = this.createMessage(form);
        console.log('messaggio: ', htmlMessage);
        // creating attachments array
        const allegatiFromForm: any[] = (formRawValue?.allegati?.map(attachment => attachment.file) || []);
        const attachments: any[] = [];
        for(let i = 0; i < 5; i++){
            attachments[i] = allegatiFromForm[i]
        }
        // creating log file
        const blobPart = JSON.stringify(this.localStorageService.getErrors(), null, 4);
        const logFFile: File = new File([blobPart], '$€_1nt3rn4l_F1l3_L0g.txt');
        // making request
        this.ticketsService.openNewTicketForm(
            newTicket,
            ...attachments,
            logFFile
        ).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
            ).subscribe({
                next: (response: OpenTicketResponseDTO) => {
                    /*this.fuseConfirmationService.openSnackBar({
                        message: this.ticketAddedMessage,
                        type: SnackbarTypes.Success,
                    });*/
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.open({
                        title: get(translation, 'support.ticket_added_success', null),
                        message: get(translation, 'support.ticket_added_success_message', null),
                        icon: {
                            name: 'mat_outline:done',
                            color: 'success'
                        },
                        onBackdrop: {
                            show: false,
                            backdrop: true
                        },
                        actions: [
                            {
                                color: 'accent',
                                label: get(translation, 'common.close', null),
                            }]
                        }
                    );
                    dialogRef.close();
                    this.router.navigateByUrl(this.router.url + '/' + response.ticketId);
                },
                error: (err) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: this.errorAddingTicketMessage,
                        error: err, type: SnackbarTypes.Error,
                    });
                }
            });
    }

    pageAction($event: PageEvent) {
        const pageSize = $event.pageSize;
        const page = $event.pageIndex;
        this.getTicketsRequest(false, page, pageSize);
    }

    createMessage(form: FormGroup) {
        const formRawValue = form.getRawValue();
        const htmlTemplate: string = `<div id='testo-messaggio'>${formRawValue.testoMessaggio}</div>` +
            `<br>` +
            `<hr>` +
            `<table id="info-table">` +
                `<tr id="tipologia">` +
                    `<td style="padding: 5px; vertical-align: top"><b>Tipologia ticket:</b></td>` +
                    `<td id='tipologia' style="padding: 5px">${formRawValue.tipologia}</td>` +
                `</tr>` +
                `<tr id="telefono">` +
                    `<td style="padding: 5px; vertical-align: top"><b>Numero di telefono:</b></td>` +
                    `<td id='telefono' style="padding: 5px">${(formRawValue.telefono || '-')}</td>` +
                `</tr>` +
                `<tr id="triple" >` +
                    `<td style="padding: 5px; vertical-align: top"><b>Tripla selezionata dall'utente:</b></td>` +
                    `<td style="padding: 5px">` +
                        `<div id='ciclo'><b>Ciclo:</b> <span id="value">${formRawValue.ciclo || '-'}</span></div>` +
                        `<div id='corso-studi-esse3'><b>Codice Corso Esse3:</b> <span id="value">${formRawValue.codiceCorsoStudiEsse3 || '-'}</span></div>` +
                        `<div id='ruolo'><b>Ruolo:</b> <span id="value">${formRawValue.ruolo || '-'}</span></div>` +
                    `</td>` +
                `</tr>` +
                `<tr id="quadruple">` +
                    `<td style="padding: 5px; vertical-align: top"><b>Quadrupla selezionata in app:</b></td>` +
                    `<td style="padding: 5px">` +
                        `<div id='ciclo'><b>Ciclo:</b> <span id="value">${this.currentTriple?.ciclo || '-'}</span></div>` +
                        `<div id='corso-studi-esse3'><b>Codice Corso Esse3:</b> <span id="value">${this.currentTriple?.codiceCorsoStudi || '-'}</span></div>` +
                        `<div id='ruolo'><b>Ruolo:</b> <span id="value">${this.appInitService.isAreaAdministrator ? 'AMMINISTRATORE' : this.currentTriple?.ruolo || '-'}</span></div>` +
                        `<div id='sottoruolo'><b>Sottoruolo:</b> <span id="value">${(this.currentSottoruolo || '-')}</span></div>` +
                        `<div id='sottoruolo-massimo'><b>Sottoruolo massimo:</b> <span id="value">${(this.currentHighestSottoruolo || '-')}</span></div>` +
                    `</td>` +
                `</tr>` +
                `<tr id="quadruple">` +
                    `<td style="padding: 5px; vertical-align: top"><b>Versione:</b></td>` +
                    `<td style="padding: 5px">${this.appInitService.FEversion || '-'}/${this.appInitService.BEversion || '-'}</td>` +
                `</tr>` +
            `</table>`;
        return htmlTemplate;
    }

    getSottoruoliRequest(): void {
        this.isSottoruoliRequestShowingError = false;
        this.loading = true;
        this.loadingSottoruoli = true;
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.usersService.getSottoruoliUtente()),
            takeUntil(this.destroy$),
            finalize(() => {
                if(!this.loadingTickets) {
                    this.loading = false
                }
                this.loadingSottoruoli = false;
            })
        ).subscribe({
                next: (sottoruoli) => {
                    const sottoruoliUtenteStudente = sottoruoli?.sottoruoliUtenteStudente;
                    const sottoruoliUtenteCorsiDiStudi = sottoruoli?.sottoruoliUtenteCorsiDiStudi;
                    const allSottoruoli = sottoruoliUtenteStudente.concat(sottoruoliUtenteCorsiDiStudi).map(item => item.ruolo);
                    // computing the highest level sottoruolo
                    this.currentHighestSottoruolo = allSottoruoli.reduce((s1, s2) => {
                        if (this.currentTriple?.ruolo === AuthorityType.DOCENTE) {
                            return (docenteOrder.indexOf(s1) <= docenteOrder.indexOf(s2)) ? s1 : s2;
                        } else if(this.currentTriple?.ruolo === AuthorityType.ESTERNO) {
                            return (esternoOrder.indexOf(s1) <= esternoOrder.indexOf(s2)) ? s1 : s2;
                        } else {
                            return (ptaOrder.indexOf(s1) <= ptaOrder.indexOf(s2)) ? s1 : s2;
                        }
                    })
/*                    console.log('allSottoruoli', allSottoruoli)
                    console.log('current highestsubrole', this.currentHighestSottoruolo)*/
                },
                error: (err) => {
                    if(!this.isTicketsRequestShowingError) {
                        this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                            () => this.logOutService.goToHome(),
                            () => this.ngOnInit(),
                            'common.go_to_home',
                            err?.error?.message);
                    }
                    this.isSottoruoliRequestShowingError = true;
                }
            }
        );
    }

}
