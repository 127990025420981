import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogChooseCoursesComponent} from './dialog-choose-courses.component';
import {TranslocoModule} from "@ngneat/transloco";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {DialogWrapModule} from "../../../layout/common/dialog-wrap/dialog-wrap.module";
import {TestoPrivacyModule} from "../../components/testo-privacy/testo-privacy.module";
import {MatDialogModule} from "@angular/material/dialog";
import {
    TestoTerminiECondizioniModule
} from "../../components/testo-termini-e-condizioni/testo-termini-e-condizioni.module";
import {GenericTableModule} from "../../components/table/generic-table/generic-table.module";
import {MatDividerModule} from "@angular/material/divider";
import {MatTabsModule} from "@angular/material/tabs";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
    declarations: [
        DialogChooseCoursesComponent
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        FlexModule,
        MatButtonModule,
        DialogWrapModule,
        TestoPrivacyModule,
        MatDialogModule,
        GenericTableModule,
        MatDividerModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatIconModule,
    ],
    exports: [
        DialogChooseCoursesComponent
    ],
})
export class DialogChooseCoursesModule {
}
