import {Injectable, Injector, SkipSelf} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {merge, omit} from 'lodash-es';
import {FuseConfirmationDialogComponent} from '@fuse/services/confirmation/dialog/dialog.component';
import {FuseConfirmationConfig, SnackbarConfig} from '@fuse/services/confirmation/confirmation.types';
import {SnackbarComponent, SnackbarTypes} from './snackbar/snackbar.component';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {get, isEmpty} from 'lodash';
import {TranslocoService} from '@ngneat/transloco';
import {HttpErrorResponse} from '@angular/common/http';
import {catchError, EMPTY, NEVER, Observable, ObservedValueOf, OperatorFunction, switchMap, throwError} from "rxjs";
import {LocalStorageService} from "../../../app/shared/service/local-storage.service";
import {LogoutService} from "../../../app/shared/service/logout.service";
import {
    DialogShowDeliberaComponent, DialogShowDeliberaI
} from "../../../app/shared/components/dialog-show-delibera/dialog-show-delibera.component";
import {AuthorityType, DeliberaInfoView} from "../../../api-clients/generated/services";
import {PathEnum} from "../../../app/app.routing";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {SupportComponent} from "../../../app/modules/landing/support/support.component";

export interface ErrorDialogConfig {
    error?: any;
}

@Injectable()
export class FuseConfirmationService {

    loadingSpinner: boolean = false;
    private _defaultConfig: FuseConfirmationConfig = {
        title: '',
        message: '',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation',
            color: 'warn'
        },
        onBackdrop: {
            show: true,
            backdrop: false,
        },
    };

    private _defaultSnackbarConfig: SnackbarConfig = {
        message: '',
        type: SnackbarTypes.Success,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 3500
    };
    private logoutService: LogoutService;
    errorDuringAppInit: boolean;


    /**
     * Constructor
     */
    constructor(
        private _matDialog: MatDialog,
        public snackBar: MatSnackBar,
        private _translocoService: TranslocoService,
        private injector: Injector,
        private router: Router,
    ) {


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !!userConfig?.onBackdrop?.function || userConfig?.onBackdrop?.backdrop,
            data: userConfig,
            panelClass: 'fuse-confirmation-dialog-panel'
        });
    }

    openSnackBar(_config: SnackbarConfig) {
        const userConfig = merge({}, this._defaultSnackbarConfig, _config);
        // if error is because functionality is locked, print the BE message
        if(userConfig?.error?.error?.code === 1027 || userConfig?.error?.error?.code === 1028){
            userConfig.message = userConfig?.error?.error?.message;
            userConfig.duration = 5500;
        }
        const config: MatSnackBarConfig = new MatSnackBarConfig();
        config.verticalPosition = userConfig.verticalPosition;
        config.horizontalPosition = userConfig.horizontalPosition;
        config.duration = userConfig.duration;
        config.data = {message: userConfig.message, type: userConfig.type};
        config.panelClass = this.getPanelClass(userConfig.type);
        this.snackBar.openFromComponent(SnackbarComponent, config);
    }

    openSnackBarGenericError(_translocoService: TranslocoService, err?: HttpErrorResponse) {
        const activeLang = _translocoService.getActiveLang()
        const translation = _translocoService.getTranslation().get(activeLang)
        const message = get(translation, 'common.not_loading_data', null)
        this.openSnackBar({
            message: err?.error?.message || message,
            type: SnackbarTypes.Error
        })
    }

    getPanelClass(type: SnackbarTypes) {
        switch (type) {
            case SnackbarTypes.Success:
                return 'mat-bg-success';
            case SnackbarTypes.Error:
                return 'mat-bg-error';
            case SnackbarTypes.Warning:
                return 'mat-bg-warn';
            default:
                return 'error';
        }
    }

    showLoader() {
        this.loadingSpinner = true;
    }

    hideLoader() {
        this.loadingSpinner = false;
    }

    openErrorDialog(errorDialogConfig: ErrorDialogConfig, _translocoService: TranslocoService, goToHome?: () => void, getData?: () => void, firstActionlabel?: string, errorMessage?: string) {
        const activeLang = _translocoService.getActiveLang();
        const translation = _translocoService.getTranslation().get(activeLang);
        let actions = [];
        if(!!getData){
            actions.push({
                function: () => getData(),
                label: get(translation, 'common.try_again', null),
                color: 'primary'
            });
        }
        if(!!goToHome){
            actions.push({
                function: () => goToHome(),
                label: get(translation, !!firstActionlabel ? firstActionlabel : 'common.go_to_home', null),
                color: 'accent'
            });
        }
        let msg = errorMessage ? errorMessage : get(translation, 'common.not_loading_data', null);
        // if error is because functionality is locked, print the BE message
        if(errorDialogConfig?.error?.error?.code === 1027 || errorDialogConfig?.error?.error?.code === 1028){
            msg = errorDialogConfig?.error?.error?.message;
        }
        this.open({
            message: msg,
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: actions
        })
    }

    errorGetDataWithNoGoToHome(_translocoService: TranslocoService, getData: () => void, errorMessage?: string) {
        const activeLang = _translocoService.getActiveLang();
        const translation = _translocoService.getTranslation().get(activeLang);
        this.open({
            message: errorMessage ? errorMessage : get(translation, 'common.not_loading_data', null),
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    function: () => getData(),
                    label: get(translation, 'common.try_again', null),
                    color: 'primary'
                },
                {
                    label: get(translation, 'common.ask_for_support', null),
                    function: (): void => {
                        this.router.navigate([PathEnum.SUPPORT]);
                    },
                    color: 'primary',
                }
            ]
        })
    }

    catchErrorCustom$(inputObs: Observable<any>, config?: FuseConfirmationConfig, returnEmpty?: boolean,
                      showRelativeError?: boolean, showGenericError?: boolean, isForLogin?: boolean, hideAssistenzaBtn?: boolean): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return catchError((error: HttpErrorResponse) => {
            console.log('CATCHERROR', error, 'showgenericerror', showGenericError)
            if(this.errorDuringAppInit){
                // if assistenza is opened, do not open another dialog
                return !!returnEmpty ? EMPTY : throwError(() => new Error());
            }
            const dialogRef = this.openModalErrorForCatchErrorCustom(config,
                showRelativeError ? error : null, showGenericError, hideAssistenzaBtn);
            return dialogRef.afterClosed().pipe(
                switchMap((value: any) => {
                    if(this.errorDuringAppInit){
                        // if assistenza is opened, do not open another dialog
                        return !!returnEmpty ? EMPTY : throwError(() => new Error());
                    }else if (!!value) {
                        return inputObs.pipe(this.catchErrorCustom$(inputObs, config, returnEmpty, undefined, isForLogin ? undefined : showGenericError, undefined, hideAssistenzaBtn));
                    } else {
                        return !!returnEmpty ? EMPTY : throwError(() => new Error());
                    }
                })
            );
        });
    }

    openModalErrorForCatchErrorCustom(config?: FuseConfirmationConfig, errorMessageFromBackend?: HttpErrorResponse, genericErrorMessage?: boolean, hideAssistenzaBtn?: boolean): MatDialogRef<FuseConfirmationDialogComponent> {
        this.logoutService = this.injector.get(LogoutService);
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const defaultConfig: FuseConfirmationConfig = {
            title: get(translation, 'common.attention', null),
            message: !!errorMessageFromBackend?.error?.message ? errorMessageFromBackend.error.message : (genericErrorMessage ? get(translation, 'error.generic_error') : get(translation, 'auth.unsuccessful_sign_in', null)),
            onBackdrop: {
                show: false,
                backdrop: false,
            },
            actions: [
                {
                    function: (): void => {
                        if(config?.openModalError?.goToHome){
                            this.logoutService.goToHome();
                        } else {
                            this.logoutService.logout();
                        }
                    },
                    label: get(translation, config?.openModalError?.goToHome ? 'common.go_to_home' : 'dialog.logout', null),
                    color: 'accent'
                },
                {
                    label: get(translation, 'common.try_again', null),
                    color: 'primary',
                    closeValue: true
                },
            ]
        };
        if(!hideAssistenzaBtn){
            defaultConfig.actions.push(
                {
                    label: get(translation, 'common.ask_for_support', null),
                    function: (): void => {
                        this.goToAssistenzaBecauseOfInitError();
                    },
                    color: 'primary',
                }
            );
        }
        const myConfiguration = !!config && !isEmpty(omit(config, 'openModalError')) ? config : defaultConfig;
        return this.open(myConfiguration);
    }

    openModalErrorForLoginError(goToLoginFn: () => void): MatDialogRef<FuseConfirmationDialogComponent> {
        this.logoutService = this.injector.get(LogoutService);
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const defaultConfig: FuseConfirmationConfig = {
            title: get(translation, 'common.attention', null),
            message: get(translation, 'auth.unsuccessful_sign_in_retry', null),
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    function: goToLoginFn,
                    label: get(translation, 'common.go_to_login', null),
                    hexColor: '#B6BFCA',
                    hexColorText: '#FFFFFF',
                },
            ]
        };
        return this.open(defaultConfig);
    }


    openDialogDelibera(dialogDelibera: DialogShowDeliberaI): MatDialogRef<DialogShowDeliberaComponent> {
        return this._matDialog.open(DialogShowDeliberaComponent, {
            autoFocus: false,
            data: dialogDelibera,
            panelClass: 'delibera-dialog'
        });
    }


    public goToAssistenzaBecauseOfInitError() {
        this.errorDuringAppInit = true;
        this.router.navigate([PathEnum.SUPPORT]);
    }

}

export const     hideDelibera = [AuthorityType.ESTERNO, AuthorityType.STUDENTE]
