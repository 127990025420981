<ng-container *transloco="let t">
    <div class="container justify-content_center" [style]="_configuration?.css">
        <div *ngIf="_configuration.tableTitle" class="w-full text-center py-1.5 font-medium text-gray-600">{{t(_configuration.tableTitle)}}</div>
        <mat-table #table [dataSource]="dataSourcePaginated" matSort
                   multiTemplateDataRows
                   class="flex flex-col items-center xl:block xl:items-start"
                   [style]="!!_configuration?.cssMatTable ? _configuration?.cssMatTable : _configuration?.css">
            <ng-container *ngFor="let colonna of _configuration.configurazioneTabella; trackBy: trackByFn"
                          matColumnDef="{{colonna.colonnaKey}}">
                <mat-header-cell *matHeaderCellDef [fxFlex]="colonna?.flex" mat-sort-header
                                 [disabled]="!colonna.enableSort"
                                 fxHide.md class="justify-center" [ngStyle]="_configuration?.css"> {{
                        t((colonna.nomeColonna || colonna.colonnaKey)) |  customTitleCase: colonna.uppercase | removeUnderscores}} </mat-header-cell>

                <mat-cell *matCellDef="let element; let idx = index" class="justify-content_center"
                          [fxFlex]="colonna?.flex"
                          [ngStyle]="_configuration?.css"
                          [ngClass]="{'cursor-pointer': _configuration.selection?.isSelectable}"
                          (click)="onCellClick(element)">

                    <ng-container [ngSwitch]="colonna.tipo">
                    <span [ngStyle]="_configuration?.css"
                          class="mobile-label"> {{t((colonna.nomeColonna || colonna.colonnaKey)) |  customTitleCase: colonna.uppercase | removeUnderscores}}</span>


                        <ng-container *ngSwitchCase="tipoColonna.SELECTION">
                            <ng-container>
                                <mat-checkbox class="m-1" color="primary"
                                              (change)="toggleSelectedRowData(element)"
                                              (click)="$event.stopPropagation()"
                                              [checked]="checkIfSelected(element[selectedColumnKeyPrimaryKey])"
                                              [disabled]="readOnly|| disableNonSelectedCheck(element) || checkIfParentIsDifferent(element) || checkIfRigaReadOnly(element) "></mat-checkbox>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.ICON">
                            <ng-container *ngFor="let icon of colonna.icon">
                                <mat-icon
                                    *ngIf="!icon.showIf || icon.showIf(element[colonna.colonnaKey])"
                                    [color]="icon.colorIcon"
                                    [matTooltip]="(icon.tootlip || '') | customTitleCase: colonna.uppercase  | removeUnderscores">{{icon.nameIcon}}</mat-icon>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="tipoColonna.STRING">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                                <div style="  text-align: center;   white-space: pre-line;"
                                     *ngIf="(colonna.colonnaKey === 'email' || colonna.colonnaKey === 'username'); else showValue">
                                    <span class="mobile-text" style="word-break: break-word;">{{value}}  </span>
                                </div>
                                <ng-template #showValue>
                            <span style="  text-align: center;   white-space: pre-line; word-break: break-word;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value  | customTitleCase: colonna.uppercase | removeUnderscores}}</span>
                                </ng-template>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.NUMBER">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                            <span style="  text-align: center;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value}}</span>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.HREF">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                            <span style="text-align: center;"
                                  (click)="goTo(colonna, element, colonna.colonnaKey); $event.stopPropagation()"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text cursor-pointer">
                                {{ value}}</span>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.AMOUNT">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                            <span style="  text-align: center;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value | currencyEuro}}</span>
                            </ng-container>
                        </ng-container>


                        <ng-container *ngSwitchCase="tipoColonna.TEXT_BUTTON">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div><span style="  text-align: center;   white-space: pre-line"
                                               *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                               class="mobile-text"
                                    >
                                {{ value}}</span>
                                    </div>

                                    <mat-icon matTooltip="Visualizza in tabella" *ngIf="!!value"
                                              style="margin-left: 5px"
                                              class="cursor-pointer icon-size-4"
                                              (click)="colonna.dialog.openDialog(element[colonna.dialog.fieldValueDialog]); $event.stopPropagation()">
                                        open_in_new
                                    </mat-icon>
                                </div>


                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="tipoColonna.BOOLEAN">
                            <ng-container *ngIf="!!colonna.convertiValoreBooleanInIcon">
                                <mat-icon
                                    *ngIf="convertiValoreInBo(colonna, element[colonna.colonnaKey]) as iconConfig"
                                    [color]="iconConfig.colorIcon"
                                    [matTooltip]="(iconConfig.tootlip || '') | customTitleCase: colonna.uppercase | removeUnderscores">{{iconConfig.nameIcon}}</mat-icon>
                            </ng-container>
                            <ng-container *ngIf="!!colonna.convertiValoreBoolean">
                                <span
                                    class="mobile-text">{{ t(getValue(colonna, element[colonna.colonnaKey]))| customTitleCase: colonna.uppercase | removeUnderscores}}</span>
                            </ng-container>


                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.ACTION">
                            <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%"
                                 [class.custom-button]="_configuration.customButton">
                                <div fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%">
                                    <ng-container
                                        *ngFor="let button of getButton(colonna, element[colonna.valueKeyForFuction || colonna.colonnaKey])">
                                        <ng-container *ngIf="!button.hide">
                                            <button *ngIf="show(button, element, button?.valueShow) && !button.isIcon"
                                                    style="margin: 2px; box-shadow: none"
                                                    [color]="button.color" mat-mini-fab
                                                    [matTooltip]="t(button.tooltip) || ''"
                                                    (click)="clickAction.emit({tipoClick: button.click, index: idx, value: element, parentValue: parentElement,  data: button.data}); $event.stopPropagation()">
                                                <mat-icon>{{button.nameIconButton}}</mat-icon>
                                            </button>

                                            <mat-icon *ngIf="button.isIcon && show(button, element, button?.valueShow)"
                                                      [color]="button.color"
                                                      [matTooltip]="button?.iconTooltip || button?.tooltip">{{button.nameIconButton}}</mat-icon>
                                        </ng-container>

                                    </ng-container>
                                </div>

                            </div>

                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.ACTION_MENU">
                            <button style="box-shadow: unset !important;"
                                    *ngIf="!colonna?.show || colonna?.show(element[colonna.valueCheck || colonna.colonnaKey], element)"
                                    color="accent"
                                    mat-mini-fab
                                    (click)="$event.stopPropagation()"
                                    [matMenuTriggerFor]="action"
                            >
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu class="custom-mat-menu-action max-w-[unset]"  [xPosition]="'after'" #action="matMenu">

                                <ng-container
                                    *ngFor="let button of getButton(colonna, element[colonna.valueKeyForFuction || colonna.colonnaKey])">
                                    <ng-container *ngIf="!button.hide">
                                        <button *ngIf="show(button, element, button?.valueShow) && !button.isIcon"
                                                style="margin: 2px"
                                                mat-menu-item
                                                (click)="clickAction.emit({tipoClick: button.click, index: idx, value: element, parentValue: parentElement,  data: button.data}); $event.stopPropagation()">
                                            <mat-icon>{{button.nameIconButton}}</mat-icon>
                                            <span class="ml-2">{{t(button.tooltip) || ''}}</span>
                                        </button>

                                        <mat-icon *ngIf="button.isIcon && show(button, element, button?.valueShow)"
                                                  [color]="button.color"
                                                  [matTooltip]="button?.iconTooltip || button?.tooltip">{{button.nameIconButton}}</mat-icon>
                                    </ng-container>

                                </ng-container>

                            </mat-menu>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.CHIP_USER">
                            <ng-container *ngIf="getValueForChip(colonna, element, colonna.colonnaKey) as chipList">

                                <mat-chip-list [style]="'width:93%'">
                                    <div class="chips-container w-full justify-center items-center">
                                        <ng-container *ngFor="let chipItem of chipList;">
                                            <mat-chip
                                                [ngClass]="colonna.isChipDisabled || !chipItem.codiceFiscale ? 'pointer-events-none' : 'cursor-pointer'"
                                                (click)="clickAction.emit({tipoClick: chipClickType, index: idx, value: chipItem}); $event.stopPropagation()"
                                                [matTooltip]="buildNomeCompleto(chipItem).toUpperCase()"
                                                class="chip-width user-chip justify-between gap-1"
                                                color="primary">
                                                <span class="overflow-hidden">{{buildNomeCompleto(chipItem) | uppercase}}</span>
                                                <mat-icon *ngIf="!colonna.isChipDisabled && !!chipItem.codiceFiscale">info</mat-icon>
                                            </mat-chip>
                                        </ng-container>
                                    </div>
                                </mat-chip-list>

                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.CHIP_STATUS">
                            <ng-container *ngIf="getValueForStatus(colonna, element, colonna.colonnaKey) as chipStatus">
                                <mat-chip-list [style]="colonna.chipWidthAuto ? 'width: auto' : 'width:93%'">
                                    <div class="chips-container w-full justify-center items-center text-center">
                                        <mat-chip [class]="chipStatus | statusEnumToColorTheme: colonna.statusType "
                                                  [matTooltip]="!!colonna.tooltip ? t(colonna.tooltip(chipStatus, element)) : t(chipStatus | statusEnumToLanguagePath: colonna.statusType: element[colonna.sottoruoloKey])"
                                                  class="chip-width user-chip flex flex-row justify-center">
                                            <span>{{t(chipStatus | statusEnumToLanguagePath: colonna.statusType: element[colonna.sottoruoloKey])}}</span>
                                        </mat-chip>
                                    </div>
                                </mat-chip-list>
                            </ng-container>
                        </ng-container>

                    </ng-container>


                </mat-cell>
            </ng-container>



            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: _configuration.sticky"
                            class="w-full"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex;"

                     class="w-full"
                     [ngClass]="{'highlighted-row': _configuration.highlightRowWhen && _configuration.highlightRowWhen(row), 'bg-slate-100': i % 2 == 0 && !_configuration.highlightRowWhen}"></mat-row>
            <tr class="mat-row margin" *matNoDataRow fxLayout="row" fxLayoutAlign="center center">
                <td class="mat-cell justify-content_center" [attr.colspan]=displayedColumns.length>
                    <br>{{t(_configuration?.messaggioDatiAssenti) || messaggioDatiAssenti}}<br>
                </td>
            </tr>


        </mat-table>

        <div *ngIf="!_configuration?.hidePaginator && this.dataSourcePaginated.data.length > 0" class="paginator"
             fxLayout="row" fxLayoutAlign="end end"
             [ngStyle]="!!_configuration?.cssMatPaginator ? _configuration?.cssMatPaginator : _configuration?.css">
            <mat-paginator [length]="totalElements" [pageSize]="_configuration?.pageSize || 25"
                           [ngStyle]="_configuration?.css"
                           [pageSizeOptions]="_configuration?.pageSizeOptions || [25, 50, 100]" showFirstLastButtons
                          >
            </mat-paginator>
        </div>
    </div>
</ng-container>




