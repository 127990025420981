/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddAmbitoOffertaFormativaDTO } from '../model/addAmbitoOffertaFormativaDTO';
import { AddAttivitaExtraOffertaFormativaDTO } from '../model/addAttivitaExtraOffertaFormativaDTO';
import { AddAttivitaTrasversaleOffertaFormativaDTO } from '../model/addAttivitaTrasversaleOffertaFormativaDTO';
import { AddCorsoOffertaFormativaDTO } from '../model/addCorsoOffertaFormativaDTO';
import { AddDeliberaOffertaFormativaDTO } from '../model/addDeliberaOffertaFormativaDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { GetOffertaFormativaInfoDTO } from '../model/getOffertaFormativaInfoDTO';
import { UpdateAmbitoOffertaFormativaDTO } from '../model/updateAmbitoOffertaFormativaDTO';
import { UpdateAttivitaExtraOffertaFormativaDTO } from '../model/updateAttivitaExtraOffertaFormativaDTO';
import { UpdateAttivitaTrasversaleOffertaFormativaDTO } from '../model/updateAttivitaTrasversaleOffertaFormativaDTO';
import { UpdateCorsoOffertaFormativaDTO } from '../model/updateCorsoOffertaFormativaDTO';
import { UpdateCorsoOffertaFormativaPubblicaDTO } from '../model/updateCorsoOffertaFormativaPubblicaDTO';
import { UpdateDelibereOffertaFormativaDTO } from '../model/updateDelibereOffertaFormativaDTO';
import { UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO } from '../model/updateGuidaCoordinatoreOffertaFormativaLinguaDTO';
import { UpdateOffertaFormativaDTO } from '../model/updateOffertaFormativaDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OfferteFormativeService {

    protected basePath = 'https://api-dev-1-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Aggiunge un Ambito all&#x27;offerta formativa di un ciclo corso di studi.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAmbitoOffertaFormativa(body: AddAmbitoOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public addAmbitoOffertaFormativa(body: AddAmbitoOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public addAmbitoOffertaFormativa(body: AddAmbitoOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public addAmbitoOffertaFormativa(body: AddAmbitoOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addAmbitoOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('post',`${this.basePath}/api/v1/offerta-formativa/ambito`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge un attività extra all&#x27;offerta formativa di un ciclo corso di studi.
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAttivitaExtraOffertaFormativa(body: AddAttivitaExtraOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public addAttivitaExtraOffertaFormativa(body: AddAttivitaExtraOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public addAttivitaExtraOffertaFormativa(body: AddAttivitaExtraOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public addAttivitaExtraOffertaFormativa(body: AddAttivitaExtraOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addAttivitaExtraOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('post',`${this.basePath}/api/v1/offerta-formativa/attivita-extra`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge un&#x27;attività trasversale all&#x27;offerta formativa di un ciclo corso di studi.
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAttivitaTrasversaleOffertaFormativa(body: AddAttivitaTrasversaleOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public addAttivitaTrasversaleOffertaFormativa(body: AddAttivitaTrasversaleOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public addAttivitaTrasversaleOffertaFormativa(body: AddAttivitaTrasversaleOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public addAttivitaTrasversaleOffertaFormativa(body: AddAttivitaTrasversaleOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addAttivitaTrasversaleOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('post',`${this.basePath}/api/v1/offerta-formativa/attivita-trasversale`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge un corso all&#x27;offerta formativa di un ciclo corso di studi.
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCorsoOffertaFormativa(body: AddCorsoOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public addCorsoOffertaFormativa(body: AddCorsoOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public addCorsoOffertaFormativa(body: AddCorsoOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public addCorsoOffertaFormativa(body: AddCorsoOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCorsoOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('post',`${this.basePath}/api/v1/offerta-formativa/corso`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di aggiungere una delibera a quelle associate a un offerta formativa.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDelibereOffertaFormativa(body: AddDeliberaOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public addDelibereOffertaFormativa(body: AddDeliberaOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public addDelibereOffertaFormativa(body: AddDeliberaOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public addDelibereOffertaFormativa(body: AddDeliberaOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addDelibereOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/offerta-formativa/add-delibera`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove un ambito dell&#x27;offerta formativa se non pubblica oppure se non associato a nessun corso
     * 
     * @param idAmbitoOffertaFormativa id dell&#x27;ambito offerta formativa
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAmbitoOffertaFormativa(idAmbitoOffertaFormativa: string, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public deleteAmbitoOffertaFormativa(idAmbitoOffertaFormativa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public deleteAmbitoOffertaFormativa(idAmbitoOffertaFormativa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public deleteAmbitoOffertaFormativa(idAmbitoOffertaFormativa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idAmbitoOffertaFormativa === null || idAmbitoOffertaFormativa === undefined) {
            throw new Error('Required parameter idAmbitoOffertaFormativa was null or undefined when calling deleteAmbitoOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('delete',`${this.basePath}/api/v1/offerta-formativa/ambito/${encodeURIComponent(String(idAmbitoOffertaFormativa))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove o archivia un corso/attività dell&#x27;offerta formativa
     *
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param archive boolean per l&#x27;archiviazione
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCorsoAttivitaOffertaFormativa(idCorsoInOffertaFormativa: string, archive?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public deleteCorsoAttivitaOffertaFormativa(idCorsoInOffertaFormativa: string, archive?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public deleteCorsoAttivitaOffertaFormativa(idCorsoInOffertaFormativa: string, archive?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public deleteCorsoAttivitaOffertaFormativa(idCorsoInOffertaFormativa: string, archive?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling deleteCorsoAttivitaOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('delete',`${this.basePath}/api/v1/offerta-formativa/${encodeURIComponent(String(idCorsoInOffertaFormativa))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce tutti le tipologie di documento censite nelle attività trasversali
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDistinctTipiDocumentoAttivitaTrasversale(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getAllDistinctTipiDocumentoAttivitaTrasversale(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getAllDistinctTipiDocumentoAttivitaTrasversale(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getAllDistinctTipiDocumentoAttivitaTrasversale(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/v1/offerta-formativa/tipi-documento`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;offerta formativa di un ciclo corso di studi.
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOffertaFormativa(observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public getOffertaFormativa(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public getOffertaFormativa(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public getOffertaFormativa(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('get',`${this.basePath}/api/v1/offerta-formativa/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;offerta formativa di un ciclo corso di studi di cui l&#x27;utente loggato è DOCENTE_CORSO.
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOffertaFormativaOfCurrentDocenteCorso(observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public getOffertaFormativaOfCurrentDocenteCorso(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public getOffertaFormativaOfCurrentDocenteCorso(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public getOffertaFormativaOfCurrentDocenteCorso(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('get',`${this.basePath}/api/v1/offerta-formativa/for-docente-corso`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di scaricare il pdf dell&#x27;offerta formativa da deliberare.
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfOffertaFormativaDaDeliberare(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfOffertaFormativaDaDeliberare(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfOffertaFormativaDaDeliberare(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfOffertaFormativaDaDeliberare(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/offerta-formativa/pdf`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: "blob" as "json"
            }
        );
    }

    /**
     * Modifica un Ambito dell&#x27;offerta formativa di un ciclo corso di studi.
     * 
     * @param body 
     * @param idAmbitoOffertaFormativa id dell&#x27;ambito offerta formativa
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAmbitoOffertaFormativa(body: UpdateAmbitoOffertaFormativaDTO, idAmbitoOffertaFormativa: string, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public updateAmbitoOffertaFormativa(body: UpdateAmbitoOffertaFormativaDTO, idAmbitoOffertaFormativa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public updateAmbitoOffertaFormativa(body: UpdateAmbitoOffertaFormativaDTO, idAmbitoOffertaFormativa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public updateAmbitoOffertaFormativa(body: UpdateAmbitoOffertaFormativaDTO, idAmbitoOffertaFormativa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAmbitoOffertaFormativa.');
        }

        if (idAmbitoOffertaFormativa === null || idAmbitoOffertaFormativa === undefined) {
            throw new Error('Required parameter idAmbitoOffertaFormativa was null or undefined when calling updateAmbitoOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('put',`${this.basePath}/api/v1/offerta-formativa/ambito/${encodeURIComponent(String(idAmbitoOffertaFormativa))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica un attività extra dell&#x27;offerta formativa di un ciclo corso di studi.
     *
     * @param body
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAttivitaExtraOffertaFormativa(body: UpdateAttivitaExtraOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public updateAttivitaExtraOffertaFormativa(body: UpdateAttivitaExtraOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public updateAttivitaExtraOffertaFormativa(body: UpdateAttivitaExtraOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public updateAttivitaExtraOffertaFormativa(body: UpdateAttivitaExtraOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAttivitaExtraOffertaFormativa.');
        }

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling updateAttivitaExtraOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('put',`${this.basePath}/api/v1/offerta-formativa/attivita-extra/${encodeURIComponent(String(idCorsoInOffertaFormativa))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica un attività trasversale dell&#x27;offerta formativa di un ciclo corso di studi.
     *
     * @param body
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAttivitaTrasversaleOffertaFormativa(body: UpdateAttivitaTrasversaleOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public updateAttivitaTrasversaleOffertaFormativa(body: UpdateAttivitaTrasversaleOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public updateAttivitaTrasversaleOffertaFormativa(body: UpdateAttivitaTrasversaleOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public updateAttivitaTrasversaleOffertaFormativa(body: UpdateAttivitaTrasversaleOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAttivitaTrasversaleOffertaFormativa.');
        }

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling updateAttivitaTrasversaleOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('put',`${this.basePath}/api/v1/offerta-formativa/attivita-trasversale/${encodeURIComponent(String(idCorsoInOffertaFormativa))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica un corso dell&#x27;offerta formativa di un ciclo corso di studi.
     *
     * @param body
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public updateCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public updateCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public updateCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCorsoOffertaFormativa.');
        }

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling updateCorsoOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('put',`${this.basePath}/api/v1/offerta-formativa/corso/${encodeURIComponent(String(idCorsoInOffertaFormativa))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare le delibere associate a un offerta formativa.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDelibereOffertaFormativa(body: UpdateDelibereOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public updateDelibereOffertaFormativa(body: UpdateDelibereOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public updateDelibereOffertaFormativa(body: UpdateDelibereOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public updateDelibereOffertaFormativa(body: UpdateDelibereOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDelibereOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('put',`${this.basePath}/api/v1/offerta-formativa/update-delibere`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica solo dei docenti e/o gli ambiti per un corso di un offerta formativa.
     * 
     * @param body 
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDocentiEdAmbitiInCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaPubblicaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public updateDocentiEdAmbitiInCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaPubblicaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public updateDocentiEdAmbitiInCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaPubblicaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public updateDocentiEdAmbitiInCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaPubblicaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDocentiEdAmbitiInCorsoOffertaFormativa.');
        }

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling updateDocentiEdAmbitiInCorsoOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('put',`${this.basePath}/api/v1/offerta-formativa/corso/${encodeURIComponent(String(idCorsoInOffertaFormativa))}/update-docenti-e-ambiti`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna la Guida Coordinatore su offerta formativa.
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGuidaCoordinatoreLinguaOffertaFormativa(body: UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public updateGuidaCoordinatoreLinguaOffertaFormativa(body: UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public updateGuidaCoordinatoreLinguaOffertaFormativa(body: UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public updateGuidaCoordinatoreLinguaOffertaFormativa(body: UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateGuidaCoordinatoreLinguaOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('put',`${this.basePath}/api/v1/offerta-formativa/guida-coordinatore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare lo stato, la configurazione e le delibere associate a un offerta formativa.
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOffertaFormativa(body: UpdateOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<GetOffertaFormativaInfoDTO>;
    public updateOffertaFormativa(body: UpdateOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOffertaFormativaInfoDTO>>;
    public updateOffertaFormativa(body: UpdateOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOffertaFormativaInfoDTO>>;
    public updateOffertaFormativa(body: UpdateOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetOffertaFormativaInfoDTO>('put',`${this.basePath}/api/v1/offerta-formativa/update`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
