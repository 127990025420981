<ng-container>
    <div class="flex flex-col form-width form-panel p-6"
         style="align-self: center ">


        <ng-container *transloco="let t">
            <ng-container *ngFor="let form of formGroupConfig">
                <ng-container [ngSwitch]="form.type">

                    <ng-container *ngSwitchCase="type.TEXT">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full" appearance="outline"
                                            *ngIf="getFormControl(form.name) as formControl"
                                          >
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <input matInput
                                       [placeholder]="form.noTransloco || t(form.transloco)"
                                       (blur)="formControl.setValue(formControl?.value?.trim())"
                                       [formControl]="formControl">
                                <mat-error *ngIf="formControl.hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('pattern')">
                                    {{t(form?.regex?.text)}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('noSpace')">
                                    {{t('form.no_space')}}
                                </mat-error>
                                <mat-error *ngIf="!formControl.hasError('required') && formControl.hasError('whitespace')">
                                    {{t('form.whitespace')}}
                                </mat-error>
                                <mat-hint *ngIf="form?.hint">{{form.hint}}</mat-hint>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.AMOUNT">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full" appearance="outline"
                                            *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <input matInput
                                       currencyMask
                                       [options]="{ prefix: '',  suffix: ' €',
                                       allowNegative: false,
                                       thousands: '.', decimal: ',', align: 'left' }"
                                       [placeholder]="form.noTransloco || t(form.transloco)" [formControl]="formControl"
                                >
                                <mat-error *ngIf="formControl.hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('max')">
                                    <ng-container *ngIf="form.max.customText">
                                        {{form?.max?.customText}}
                                    </ng-container>
                                    <ng-container *ngIf="!form.max.customText">
                                        {{t(form?.max?.text) + ' ' + ('(' + (form?.max?.number | currencyEuro) + ')')}}
                                    </ng-container>

                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('min')">
                                    <ng-container *ngIf="form.max.customText">
                                        {{form?.min?.customText}}
                                    </ng-container>
                                    <ng-container *ngIf="!form.max.customText">
                                        {{t(form?.min?.text) + ' ' + ('(' + (form?.min?.number | currencyEuro) + ')')}}
                                    </ng-container>
                                </mat-error>
                                <mat-hint *ngIf="form?.hint">{{form.hint}}</mat-hint>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="type.NUMBER">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full" appearance="outline"
                                            *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <input
                                    *ngIf="!form?.onlyPositiveWithOneDecimal; else positiveWithOneDecimalTemplate"
                                    matInput [placeholder]="form.noTransloco || t(form.transloco)"
                                    [type]="form?.onlyPositiveIntegers ? 'text' : 'number'"
                                    (keypress)="form?.onlyPositiveIntegers ? keyPressNumbers($event) : undefined"
                                    [formControl]="formControl"
                                >
                                <ng-template #positiveWithOneDecimalTemplate>
                                    <input matInput
                                           [placeholder]="form.noTransloco || t(form.transloco)"
                                           [type]="'number'"
                                           [min]="0.1"
                                           appOnlyDecimalNumbers
                                           [numOfDecimal]="1"
                                           [formControl]="formControl"
                                    >
                                </ng-template>

                                <mat-error *ngIf="formControl.hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('pattern')">
                                    {{t(form?.regex?.text)}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('max')">
                                    <ng-container *ngIf="form.max.customText">
                                        {{form?.max?.customText}}
                                    </ng-container>
                                    <ng-container *ngIf="!form.max.customText">
                                        {{t(form?.max?.text) + ' ' + ('(' + form?.max?.number + ')')}}
                                    </ng-container>

                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('min')">
                                    <ng-container *ngIf="form.max.customText">
                                        {{form?.min?.customText}}
                                    </ng-container>
                                    <ng-container *ngIf="!form.max.customText">
                                        {{t(form?.min?.text) + ' ' + ('(' + form?.min?.number + ')')}}
                                    </ng-container>

                                </mat-error>
                                <mat-error *ngIf="!formControl.hasError('required') && formControl.hasError('whitespace')">
                                    {{t('form.whitespace')}}
                                </mat-error>
                                <mat-hint *ngIf="form?.hint">{{form.hint}}</mat-hint>
                            </mat-form-field>
                        </ng-container>

                    </ng-container>


                    <ng-container *ngSwitchCase="type.SELECT">
                        <ng-container *ngIf="form.show">
                            <ng-container>
                            <mat-form-field class="w-full" appearance="outline"
                                            *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <mat-select [disabled]="form.readOnlyForm"
                                    [formControl]="formControl" [multiple]="form.multiple">
                                    <mat-option *ngIf="!form.multiple" [value]="form?.defaultOption?.value || undefined"> {{ form?.defaultOption?.key|| '-'}}</mat-option>
                                    <ng-container *ngIf="!!form.options && form?.options?.length > 0, else keyTmp">
                                        <mat-option *ngFor="let option of form.options"
                                                    [value]="option">{{(option || '') | dynamicPipe: form?.pipe }}
                                        </mat-option>
                                    </ng-container>
                                    <ng-template #keyTmp>
                                        <mat-option *ngFor="let option of form.optionsKeyValue"
                                                    [value]="option.key">{{(option.value || '') | dynamicPipe: form?.pipe }}
                                        </mat-option>
                                    </ng-template>

                                </mat-select>
                                <mat-error *ngIf="formControl.hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('pattern')">
                                    {{t(form?.regex?.text)}}
                                </mat-error>
                                <mat-hint *ngIf="form?.hint">{{form.hint}}</mat-hint>
                            </mat-form-field>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.DATA">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full mat-button-datapicker" appearance="outline">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <input matInput
                                       [placeholder]="form.noTransloco || t(form.transloco)"
                                       [value]="dateValue(form.name)"
                                       [max]="form?.dateMax"
                                       [min]="form?.dateMin"
                                       readonly
                                       [formControl]="getFormControl(form.name)"
                                       (dateInput)="event('input', $event, form.name)"
                                       [matDatepicker]="picker1"
                                       [matDatepickerFilter]="form.dateFilter? form.dateFilter: undefined"
                                >
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-error *ngIf="getFormControl(form.name).hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                                <mat-hint *ngIf="form?.hint">{{form.hint}}</mat-hint>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.DATAORA">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full datetime-form-field" appearance="outline">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <input matInput
                                       type="datetime-local"
                                       [placeholder]="form.noTransloco || t(form.transloco)"
                                       [max]="form?.dateMax"
                                       [min]="form?.dateMin"
                                       [formControl]="getFormControl(form.name)"
                                >
                                <mat-error *ngIf="getFormControl(form.name).hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                                <mat-hint *ngIf="form?.hint">{{form.hint}}</mat-hint>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.TEXTAREA">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full" appearance="outline" *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <textarea
                                    cdkTextareaAutosize
                                    cdkAutosizeMinRows="3"
                                    cdkAutosizeMaxRows="3"
                                    (blur)="formControl.setValue(formControl?.value?.trim())"
                                    [placeholder]="form.noTransloco ||  t(form.transloco)" [formControl]="formControl"
                                    matInput
                                ></textarea>

                                <mat-error *ngIf="formControl.hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('pattern')">
                                    {{t(form?.regex?.text)}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('maxlength')">
                                    {{t('form.max_length')}}
                                </mat-error>
                                <mat-error *ngIf="!formControl.hasError('required') && formControl.hasError('whitespace')">
                                    {{t('form.whitespace')}}
                                </mat-error>
                                <mat-hint *ngIf="form?.hint">{{form.hint}}</mat-hint>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.FILE">
                        <ng-container *ngIf="form.show">
                        <app-input-file [label]="form.transloco" [labelNoTranslate]="form.noTransloco" [listFileAccepted]="form.optionFile?.listaAccepted" [sizeMB]="form.optionFile?.sizeMb" [form]="getFormControl(form.name)" (uploadImgEmitter)="getUploadedFile($event, form.name)"></app-input-file>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.MULTIFILE">
                        <ng-container [formGroup]="formGroup">
                            <ng-container *ngIf="form.show">
                                <mat-label class="mb-2"><strong>{{t(form.transloco)}}</strong></mat-label>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                            <input-file-with-preview fileLimit="20"
                                                                     [addLabel]="t('support.attach')"
                                                                     [formControlName]="form.name"
                                                                     (rejectedFile)="fileRejected($event)"
                                                                     placeholder="">
                                            </input-file-with-preview>
                                        </div>
                                    </div>
                                </div>
                                <mat-error *ngIf="getFormControl(form.name).hasError('required')"
                                           class="text-xs mt-1 ml-0.5">
                                    {{t('form.required')}}
                                </mat-error>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.BOOLEAN">
                        <ng-container *ngIf="form.show">
                            <div class="w-full flex flex-row gap-2 items-center py-3"
                                            *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <mat-checkbox class="m-1" color="primary" [formControl]="formControl">
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </ng-container>


                </ng-container>
            </ng-container>

            <ng-container *ngIf="touched">
                <mat-hint *ngFor="let error of formGroup.errors | keyvalue"
                          class="mat-error">{{t(error.value)}}</mat-hint>

            </ng-container>
        </ng-container>


    </div>
</ng-container>
