import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {distinctUntilChanged, finalize, switchMap, take, takeUntil} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogWrapI} from "../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    CicloAbilitatoViewImpl,
    CorsoDiStudiViewImpl,
    EtichettaTicketResponseDTO,
    TicketsService,
    TicketType
} from "../../../../api-clients/generated/services";
import {filter} from "rxjs/operators";
import {AppInitService} from "../../service/app-init.service";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import {get, groupBy, sortBy} from "lodash";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {uniqBy} from "lodash-es";
import {AngularEditorComponent, AngularEditorConfig} from "@kolkov/angular-editor";
import {InputFileRejected} from "../../input-file-with-preview/interfaces/input-file-rejected";
import {cellularRegex} from "../../costants/regex";
import {TicketTipologia} from "../../../modules/landing/support/support.component";
import {LogoutService} from "../../service/logout.service";
import {CustomValidators} from "../../validators/custom-validators";


interface Dictionary<T> {
    [Key: string]: T;
}

export interface DialogAddTicketMessageI extends DialogWrapI {
    onAddPressed: (form: FormGroup, dialogRef: MatDialogRef<any>) => void,
    alsoAddTicket?: boolean;
    isAreaAdministrator: boolean
}

@Component({
    selector: 'app-dialog-add-ticket-message',
    templateUrl: './dialog-add-ticket-message.component.html',
    styleUrls: ['./dialog-add-ticket-message.component.scss']
})
export class DialogAddTicketMessageComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit {

    form: FormGroup;
    loading: boolean = false;
    editorConfig: AngularEditorConfig;
    private testoMessaggioPlaceholder: string;
    corsiStudi: CorsoDiStudiViewImpl[] = [];
    listaCicliCorsiRuoli: CicloAbilitatoViewImpl[] = [];
    listaCicliCorsiRuoliGroupByCiclo: Dictionary<CicloAbilitatoViewImpl[]>;
    ruoli: string[] = [];
    typologyDesc = {
        "STANDARD": "support.typology_standard_desc",
        "CRITICA": "support.typology_critic_desc",
        "URGENTE":"support.typology_urgent_desc"
    };
    categories: EtichettaTicketResponseDTO[];
    private formNotValidMessage: string;
    private formSubmitted: boolean;
    @ViewChild(AngularEditorComponent) private testoMessaggioEditor: AngularEditorComponent;
    cicli: number[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogAddTicketMessageI,
                public dialogRef: MatDialogRef<DialogAddTicketMessageComponent>,
                private fb: FormBuilder,
                private translocoService: TranslocoService,
                protected fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private ticketsService: TicketsService,
                private logOutService: LogoutService) {
        super();
        this.translocoService.selectTranslate(data.alsoAddTicket ? 'support.message_field_placeholder' : 'support.text_message').subscribe(value => this.testoMessaggioPlaceholder = value);
        this.translocoService.selectTranslate('form.not_valid').subscribe(value => this.formNotValidMessage = value);
        this.editorConfig = {
            editable: true,
            spellcheck: true,
            height: '150px',
            minHeight: '0',
            maxHeight: 'auto',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: true,
            showToolbar: true,
            placeholder: this.testoMessaggioPlaceholder,
            defaultParagraphSeparator: '',
            defaultFontName: '',
            defaultFontSize: '',
            fonts: [
                {class: 'arial', name: 'Arial'},
            ],
            customClasses: [],
            uploadWithCredentials: false,
            sanitize: true,
            toolbarPosition: 'top',
            toolbarHiddenButtons: [
                [
                    'undo',
                    'redo',
                    'strikeThrough',
                    'subscript',
                    'superscript',
                    // 'justifyLeft',
                    // 'justifyCenter',
                    // 'justifyRight',
                    // 'justifyFull',
                    'indent',
                    'outdent',
                    // 'insertUnorderedList',
                    // 'insertOrderedList',
                    'heading',
                    'fontName'
                ],
                [
                    'fontSize',
                    'textColor',
                    'backgroundColor',
                    'customClasses',
                    // 'link',
                    // 'unlink',
                    'insertImage',
                    'insertVideo',
                    'insertHorizontalRule',
                    'removeFormat',
                    'toggleEditorMode'
                ]
            ]
        };

    }

    ngOnInit(): void {
        if(this.fuseConfirmationService.errorDuringAppInit){
            this.createForm();
        } if(this.data.isAreaAdministrator){
            this.cicli = this.appInitService.userAmministrazioneInfoCicli.cicli.map(value => value.numeroCiclo);
            this.createForm();
        } else {
            this.appInitService.listaRuoliCodiciDipartimentiObs.pipe(takeUntil(this.destroy$)).subscribe((val) => {
                this.listaCicliCorsiRuoli = val;
                this.listaCicliCorsiRuoliGroupByCiclo = groupBy(this.listaCicliCorsiRuoli, 'numeroCiclo');
                this.createForm();
            });
        }



        if(this.data?.alsoAddTicket) {
            this.getEtichettaRequest();
        }
    }

    ngAfterViewInit(){
        if(!this.data?.alsoAddTicket){
            this.testoMessaggioEditor.focus();
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    addTicketMessage() {
        this.formSubmitted = true;
        if(this.form.valid) {
            this.data.onAddPressed(this.form, this.dialogRef);
        } else {
            this.fuseConfirmationService.openSnackBar({
                message: this.formNotValidMessage,
                type: SnackbarTypes.Warning,
            });
        }
    }

    fileRejected($event: InputFileRejected) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const message = get(translation, 'upload.not_supported_file', null);
        this.fuseConfirmationService.openSnackBar({
            message: message,
            type: SnackbarTypes.Error
        })
    }

    private createForm() {
        this.form = this.fb.group({
            testoMessaggio: undefined,
            tipologia: undefined,
            categoria: undefined,
            telefono: [undefined, Validators.pattern(new RegExp(cellularRegex))],
            oggetto: undefined,
            ciclo: this.fuseConfirmationService.errorDuringAppInit ? undefined : [undefined, Validators.required],
            ruolo: this.fuseConfirmationService.errorDuringAppInit ? undefined : (this.data.isAreaAdministrator ? undefined : [undefined, Validators.required]),
            codiceCorsoStudiEsse3: this.fuseConfirmationService.errorDuringAppInit ? undefined : (this.data.isAreaAdministrator ? undefined : [undefined, Validators.required]),
            allegati: undefined
        });

        if(this.data.alsoAddTicket) {
            this.getFormControlByName('oggetto').addValidators([Validators.required, CustomValidators.noWhitespaceValidator()]);
        }
        this.getFormControlByName('testoMessaggio').addValidators([Validators.required, CustomValidators.noEmptyInnerHTMLValidator()]);
        this.subscribeToTriplaFormValueChange();
        if(!this.data.alsoAddTicket){
            this.form.get('ciclo').disable();
        }
        this.form.get('codiceCorsoStudiEsse3').disable();
        this.form.get('ruolo').disable();
    }

    getFormControlByName(formControlName: string) {
        return this.form?.get(formControlName);
    }

    private subscribeToTriplaFormValueChange() {
        //nell'area di amministrazione si seleziona solo il ciclo
        if(!this.data.isAreaAdministrator){
            const cicloFormControl = this.getFormControlByName('ciclo');
            const ruoloFormControl = this.getFormControlByName('ruolo');
            const codiceCorsoStudiFormControl = this.getFormControlByName('codiceCorsoStudiEsse3');
            cicloFormControl?.valueChanges.pipe(
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            ).subscribe((val) => {
                this.corsiStudi = sortBy(uniqBy(this.listaCicliCorsiRuoliGroupByCiclo[val]?.map(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi), 'codiceEsse3'), 'codiceEsse3');
                if (!!this.corsiStudi?.length) {
                    codiceCorsoStudiFormControl?.enable();
                }
                if (!this.corsiStudi.some(corso => corso.codiceEsse3 === codiceCorsoStudiFormControl?.value)) {
                    codiceCorsoStudiFormControl?.reset();
                    ruoloFormControl?.reset();
                }
                else{
                    codiceCorsoStudiFormControl?.setValue(codiceCorsoStudiFormControl?.value);
                }
            });
            codiceCorsoStudiFormControl?.valueChanges.pipe(
                takeUntil(this.destroy$)
            ).subscribe((val) => {
                this.ruoli = this.listaCicliCorsiRuoliGroupByCiclo[cicloFormControl?.value]?.filter(el => (el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === val)).map((el => el.ruoloUtenteCorsoDiStudi?.ruolo));
                if (!!this.ruoli?.length) {
                    ruoloFormControl?.enable();
                }
                if (!this.ruoli?.includes(ruoloFormControl?.value)) {
                    ruoloFormControl?.reset();
                }
            });
        }

    }

    protected readonly Object = Object;

    get typologies(): Array<any> {
        return Object.keys(TicketTipologia);
    }

    private getEtichettaRequest(){
        this.fuseConfirmationService.showLoader();
        this.form.disable();
        this.ticketsService.getListaEtichetteTicket(TicketType.ATENA).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
                this.form.enable();
                this.form.get('codiceCorsoStudiEsse3').disable();
                this.form.get('ruolo').disable();
            })
        ).subscribe({
            next: (etichette: EtichettaTicketResponseDTO[]) => {
                this.categories = etichette;
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {
                        this.dialogRef.close();
                        this.logOutService.goToHome();
                    },
                    () => this.getEtichettaRequest(),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }


    protected readonly TicketTipologia = TicketTipologia;
    protected readonly console = console;

    async pasteFromClipboard() {
        const textFromClipboard = await navigator.clipboard.readText();
        this.form.patchValue({testoMessaggio: (this.form.get('testoMessaggio')?.value || '') + textFromClipboard});
    }
}
