import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {BooleanInput} from '@angular/cdk/coercion';
import {delay, finalize, merge, of, Subscription, switchMap, take, takeUntil, throwError} from 'rxjs';
import {UserService} from 'app/core/user/user.service';
import {LogoutService} from '../../../shared/service/logout.service';
import {ImageManagerService} from "../../../shared/service/image-manager.service";
import {filter, tap} from "rxjs/operators";
import {SafeUrl} from "@angular/platform-browser";
import {LocalStorageService} from "../../../shared/service/local-storage.service";
import {
    AteneoService,
    AuthorityType, GetManualePiattaformaDTO,
    ManualePiattaformaInfoViewImpl,
    UserView
} from "../../../../api-clients/generated/services";
import {PathEnum} from "../../../app.routing";
import {FormControl} from "@angular/forms";
import {AppInitService} from "../../../shared/service/app-init.service";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {
    AbstractDefaultComponent
} from "../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import {openFileInBlankWindow} from "../../../shared/utils/utils";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {tr} from "date-fns/locale";


@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent extends AbstractDefaultComponent implements OnInit {

    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */
    @Input() showAvatar: boolean = true;
    @Input() showGoToGestionale: boolean;
    @Input() showGoToAmministrazione: boolean;
    avatar: SafeUrl;
    profile: UserView;
    ctrlAvatar = new FormControl(null);
    AuthorityType = AuthorityType;
    manuali: ManualePiattaformaInfoViewImpl[];
    loadingManuals: boolean;
    errorLoadingManuals: boolean;
    manualsRequestSubscription: Subscription;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private logoutService: LogoutService,
        private imageManagerService: ImageManagerService,
        private localStorageService: LocalStorageService,
        private router: Router,
        public appInitService: AppInitService,
        private fuseConfirmationService: FuseConfirmationService,
        private translocoService: TranslocoService,
        private ateneoService: AteneoService,
    ) {
        super();
    }

    ngOnInit(): void {
        merge(
            this.appInitService.cicloCorsoRuoloSelected$,
            this.translocoService.langChanges$
        ).subscribe(
            value => this.getManualiRequest()
        );

        this.imageManagerService.checkCurrentAvatar$.asObservable().pipe(
            tap(value => this.showAvatar = false),
            tap(value => this.avatar = value),
            tap(value => this.ctrlAvatar.setValue(value)),
        ).subscribe({
                next: (value: SafeUrl) => {
                    if (!!value) {
                        this.showAvatar = true;
                        this._changeDetectorRef.detectChanges();
                    } else {
                        this.showAvatar = false;
                    }
                }
            }
        );

        this.localStorageService.checkCurrentProfile$.asObservable().pipe(
            tap(profile => this.profile = profile)
        ).subscribe();
    }

    signOut(): void {
        this.logoutService.logout();
    }

    navigateToProfile(): void {
        if(this.appInitService.isAreaAdministrator){
            this._router.navigateByUrl('/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.PROFILE);
        } else {
            this._router.navigateByUrl('/' + PathEnum.PROFILE);
        }

    }

    goToStudentPage(): void {
        const idStudente = this.localStorageService.idStudenteByProfile;
        if (idStudente) {
            this.router.navigate([PathEnum.STUDENTS, idStudente, PathEnum.PROFILE]);
        }
    }

    goToUVDeskPage() {
        if(this.appInitService.isAreaAdministrator){
            this.router.navigate([PathEnum.ADMINISTRATOR, PathEnum.SUPPORT]);
        } else {
            this.router.navigate([PathEnum.SUPPORT]);
        }
    }

    goToGestionale(): void {
        this.appInitService.gestionaleRoleSelectionChoiceSkipped = false;
        this.appInitService.goToGestionale();
    }

    goToAmministrazione(): void {
        this.appInitService.goToAmministrazione();
    }

    getManualiRequest(){
        this.loadingManuals = true;
        this.errorLoadingManuals = false;
        this.manuali = [];
        this._changeDetectorRef.markForCheck();
        this.manualsRequestSubscription?.unsubscribe();
        this.manualsRequestSubscription = this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            delay(500),
            switchMap(() => this.ateneoService.getManualiPiattaforma()),
            takeUntil(this.destroy$),
        ).subscribe({
            next: (manuali: ManualePiattaformaInfoViewImpl[]) => {
                console.log('Obtained manuali list:', manuali);
                this.loadingManuals = false
                this.errorLoadingManuals = false;
                this.manuali = manuali;
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                this.loadingManuals = false
                this.errorLoadingManuals = true;
                this.manuali = [];
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    openManualeRequest(manuale: ManualePiattaformaInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: GetManualePiattaformaDTO = {
            nomeFile: manuale.url
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.ateneoService.downloadManualePiattaforma(requestBody)),
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (file: Blob) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                    const fileNameToDownload = manuale.url?.split('/')?.pop();
                    openFileInBlankWindow(file, fileNameToDownload);
                },
                error: (err) => {
                    console.error(err);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'student.file_download_error', null),
                        type: SnackbarTypes.Warning,
                        error: err
                    });
                }
            }
        );
    }

}
