import {Component, EventEmitter, Input, OnInit, Output, Pipe} from '@angular/core';
import {StudenteCicloStatus} from "../../../api-clients/generated/services";

@Component({
    selector: 'app-input-value-key',
    templateUrl: './input-value-key.component.html',
    styleUrls: ['./input-value-key.component.scss']
})
export class InputValueKeyComponent implements OnInit {

    @Input() label: string;
    @Input() value: string | number;
    @Input() isFlexBasis12_5: boolean = false;
    @Input() ngContentFlexRow: boolean = false;
    @Input() showDownloadButton = false;
    @Output() onDownloadButtonPressed? = new EventEmitter<string>();
    @Input() pipe?: Pipe;
    @Input() hideValue?: boolean;
    @Input() showVisualizzaButton = false;
    @Input() showChipWithClasses: string;
    @Input() subValue: string;
    @Input() subLabel: string;
    @Input() compact: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

    download() {
        this.onDownloadButtonPressed.emit();
    }

    protected readonly StudenteCicloStatus = StudenteCicloStatus;
}
