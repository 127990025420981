import {Pipe, PipeTransform} from '@angular/core';
import {
    AggiornamentoBudgetStudenteStatus,
    AggiornamentoPianoDiStudiStatus,
    AttivitaExtraPianoDiStudiStatus,
    AttivitaTrasversaleStatus, AuthorityType,
    JobStatus, MissioneStatus, OffertaFormativaStatus, OnboardingEsternoStatus,
    PeriodoDiMobilitaStudenteStatus,
    PeriodoRegistroAttivitaStatus,
    PianoDiStudiStatus,
    QuestionarioSottomessoStatus, RichiestaDiRetribuzioneMobilitaStatus,
    SpesaStudenteStatus, StudenteCicloMotivoStatus, StudenteCicloStatus,
    TicketPriority,
    TicketStatus,
    TipoModificaCorsoPianoDiStudi, TipoOperazioneStoricoMobilitaStudente, TipoPeriodoEnum
} from '../../../api-clients/generated/services';
import {
    getLanguagePathByAggiornamentoBudgetStudenteStatus,
    getLanguagePathByAggiornamentoPianoDiStudiStatus,
    getLanguagePathByAttivitaExtraPianoDiStudiStatus,
    getLanguagePathByAttivitaTrasversale,
    getLanguagePathByEsternoStatus,
    getLanguagePathByHttpStatus,
    getLanguagePathByJobStatus, getLanguagePathByMissioneStatus,
    getLanguagePathByMobilitaStatus,
    getLanguagePathByOffertaFormativaStatus,
    getLanguagePathByPeriodiRegistroStatus,
    getLanguagePathByPeriodo,
    getLanguagePathByPianoDiStudiStatus,
    getLanguagePathByQuestionarioSottomessoStatus,
    getLanguagePathByRetribuzioneMobilitaStatus,
    getLanguagePathBySpesaStudenteStatus,
    getLanguagePathByStudenteCicloMotivoStatus,
    getLanguagePathByStudenteCicloStatus,
    getLanguagePathByTicketPriority,
    getLanguagePathByTicketStatus,
    getLanguagePathByTicketTipologia,
    getLanguagePathByTipoModificaCorsoPianoDiStudi,
    getLanguagePathByTipoOperazioneMobilitaStatus
} from '../utils/utils';
import {TicketTipologia} from "../../modules/landing/support/support.component";

@Pipe({
  name: 'statusEnumToLanguagePath'
})
export class StatusEnumToLanguagePathPipe implements PipeTransform {

    transform(value: AggiornamentoPianoDiStudiStatus | PianoDiStudiStatus | TipoModificaCorsoPianoDiStudi
        | AttivitaExtraPianoDiStudiStatus | TicketStatus | TicketPriority | TicketTipologia
        | QuestionarioSottomessoStatus | SpesaStudenteStatus | JobStatus | AttivitaTrasversaleStatus
        | PeriodoDiMobilitaStudenteStatus | OnboardingEsternoStatus | OffertaFormativaStatus
        | PeriodoRegistroAttivitaStatus | RichiestaDiRetribuzioneMobilitaStatus | AggiornamentoBudgetStudenteStatus
        | TipoOperazioneStoricoMobilitaStudente | StudenteCicloStatus | StudenteCicloMotivoStatus  | MissioneStatus | string, valueEnum: any, sottoruolo?: AuthorityType): string {
        switch (valueEnum){
            case AggiornamentoPianoDiStudiStatus:
                return getLanguagePathByAggiornamentoPianoDiStudiStatus(value as AggiornamentoPianoDiStudiStatus, sottoruolo);
            case PianoDiStudiStatus:
                return getLanguagePathByPianoDiStudiStatus(value as PianoDiStudiStatus);
            case TipoModificaCorsoPianoDiStudi:
                return getLanguagePathByTipoModificaCorsoPianoDiStudi(value as TipoModificaCorsoPianoDiStudi);
            case AttivitaExtraPianoDiStudiStatus:
                return getLanguagePathByAttivitaExtraPianoDiStudiStatus(value as AttivitaExtraPianoDiStudiStatus);
            case TicketStatus:
                return getLanguagePathByTicketStatus(value as TicketStatus);
            case TicketPriority:
                return getLanguagePathByTicketPriority(value as TicketPriority);
            case TicketTipologia:
                return getLanguagePathByTicketTipologia(value as TicketTipologia);
            case QuestionarioSottomessoStatus:
                return getLanguagePathByQuestionarioSottomessoStatus(value as QuestionarioSottomessoStatus);
            case SpesaStudenteStatus:
                return getLanguagePathBySpesaStudenteStatus(value as SpesaStudenteStatus, sottoruolo);
            case JobStatus:
                return getLanguagePathByJobStatus(value as JobStatus);
            case AttivitaTrasversaleStatus:
                return getLanguagePathByAttivitaTrasversale(value as AttivitaTrasversaleStatus);
            case PeriodoDiMobilitaStudenteStatus:
                return getLanguagePathByMobilitaStatus(value as PeriodoDiMobilitaStudenteStatus, sottoruolo);
            case OnboardingEsternoStatus:
                return getLanguagePathByEsternoStatus(value as OnboardingEsternoStatus);
            case PeriodoRegistroAttivitaStatus:
                return getLanguagePathByPeriodiRegistroStatus(value as PeriodoRegistroAttivitaStatus, sottoruolo);
            case OffertaFormativaStatus:
                return getLanguagePathByOffertaFormativaStatus(value as OffertaFormativaStatus);
            case AggiornamentoBudgetStudenteStatus:
                return getLanguagePathByAggiornamentoBudgetStudenteStatus(value as AggiornamentoBudgetStudenteStatus);
            case TipoPeriodoEnum:
                return getLanguagePathByPeriodo(value as TipoPeriodoEnum);
            case RichiestaDiRetribuzioneMobilitaStatus:
                return getLanguagePathByRetribuzioneMobilitaStatus(value as RichiestaDiRetribuzioneMobilitaStatus, sottoruolo);
            case TipoOperazioneStoricoMobilitaStudente:
                return getLanguagePathByTipoOperazioneMobilitaStatus(value as TipoOperazioneStoricoMobilitaStudente);
            case StudenteCicloStatus:
                return getLanguagePathByStudenteCicloStatus(value as StudenteCicloStatus);
            case StudenteCicloMotivoStatus:
                return getLanguagePathByStudenteCicloMotivoStatus(value as StudenteCicloMotivoStatus);
            case MissioneStatus:
                return getLanguagePathByMissioneStatus(value as MissioneStatus);
            case undefined:
                return getLanguagePathByHttpStatus(value as string);
        }
    }

}
