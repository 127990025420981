<ng-container *transloco="let t">

    <button
        mat-icon-button
       (click)="trisSelectDrawer.toggle()">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
</button>

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'trisSelectDrawer'"
    [position]="'right'"
    #trisSelectDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card">
        <ng-container *ngIf="isAreaAdministrator; else gestionaleTmp">
            <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
                <div class="ml-3 text-2xl font-semibold tracking-tight">{{t('home.dep_role_cycle')}}</div>
                <button
                    class="ml-auto"
                    mat-icon-button
                    (click)="trisSelectDrawer.close()">
                    <mat-icon
                        class="text-current"
                        [svgIcon]="'heroicons_outline:x'"></mat-icon>
                </button>
            </div>

            <div class="flex flex-col w-full h-full p-6 items-center">
                <div class="w-full flex flex-col item-center p-3 space-x-0.5 " *ngIf="showButtonEdit">
                    <button mat-flat-button class="mr-2 "
                            type="button"
                            color="accent"
                            (click)="openModalModificaCicloAmm()"
                    >
                        <mat-icon
                            class="icon-size-4">edit
                        </mat-icon>
                        <span class="ml-2">
            {{t('common.edit')}}
                </span>
                    </button>
                    <hr>
                </div>


                <div class="flex flex-col w-full item-center" *ngIf="!!cicloAmmSelected">
                    <div class="flex flex-col items-center">
                        <div class=" font-bold">{{t('students_list.phd_cicle')}}</div>
                        <div class="text-2xl font-medium text-secondary">{{cicloAmmSelected}}</div>
                    </div>
                    <hr>
                </div>

                <div class="flex-row gap-2 mr-2 flex w-full" *ngIf="userAmministrazioneRolesFiltered?.length > 0">
                    <mat-chip-list class="w-full flex flex-col gap-1">
                        <mat-chip
                            *ngFor="let administrationRole of userAmministrazioneRolesFiltered"
                            class="bg-gray-200 text-gray-600 justify-between flex flex-row gap-2 cursor-pointer text-sm w-full"
                            (click)="openAdministrationRoleInfoDialog.emit(administrationRole)">
                                <span
                                    class="line-clamp-1">{{ t('administration.' + administrationRole + '_title') }}
                                </span>
                                <mat-icon class="icon-size-5">
                                    info
                                </mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>



            </div>
        </ng-container>
        <ng-template #gestionaleTmp>
            <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
                <div class="ml-3 text-2xl font-semibold tracking-tight">{{t('home.dep_role_cycle')}}</div>
                <button
                    class="ml-auto"
                    mat-icon-button
                    (click)="trisSelectDrawer.close()">
                    <mat-icon
                        class="text-current"
                        [svgIcon]="'heroicons_outline:x'"></mat-icon>
                </button>
            </div>

            <div class="flex flex-col w-full h-full p-6 items-center">
                <div class="w-full flex flex-col item-center p-3 space-x-0.5 " *ngIf="showButtonEdit">
                    <button mat-flat-button class="mr-2 "
                            type="button"
                            color="accent"
                            (click)="openModalModificaCicloCorsoRuolo()"
                    >
                        <mat-icon
                            class="icon-size-4">edit
                        </mat-icon>
                        <span class="ml-2">
            {{t('common.edit')}}
                </span>
                    </button>
                    <hr>
                </div>


                <div class="flex flex-col w-full item-center" *ngIf="!!cicloCorsoRuolo">
                    <div class="flex flex-col items-center">
                        <div class=" font-bold">{{t('students_list.phd_cicle')}}</div>
                        <div class="text-2xl font-medium text-secondary">{{cicloCorsoRuolo?.ciclo}}</div>
                    </div>
                    <hr>

                    <div class="flex flex-col items-center">
                        <div class="font-bold">{{t('common.course')}}</div>
                        <div class="text-2xl font-medium text-secondary text-center">{{(cicloCorsoRuolo?.codiceCorsoStudi || '') +' - '+ (cicloCorsoRuolo?.denominazioneCorsoStudi || '')}}</div>
                    </div>
                    <hr>

                    <div class="flex flex-col items-center">
                        <div class="font-bold">{{t('auth.role')}}</div>
                        <div class="text-2xl font-medium text-secondary">{{cicloCorsoRuolo?.ruolo}}</div>
                    </div>

                </div>



            </div>
        </ng-template>

    </div>

</fuse-drawer>

</ng-container>
