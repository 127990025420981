/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Ticket Type, usato per definire la piattaforma a cui è associato il ticket. Valori previsti: *ATENA*
 */
export type TicketType = 'ATENA';

export const TicketType = {
    ATENA: 'ATENA' as TicketType
};