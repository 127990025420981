import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AppConfig} from '../../../../../core/config/app.config';
import {Subject, takeUntil} from 'rxjs';
import {CicloCorsoRuoloInterface} from "../../../../../shared/interface/CicloCorsoRuoloInterface";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {GenericComponentDialogConfig} from "../../../../common/generic-components/generic-components.interface";
import {
    DialogSetCicloCorsoRuoloComponent
} from "../dialog-set-ciclo-corso-ruolo/dialog-set-ciclo-corso-ruolo.component";
import {MatDialog} from "@angular/material/dialog";
import {FuseDrawerComponent} from "../../../../../../@fuse/components/drawer";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {AuthorityType} from "../../../../../../api-clients/generated/services";

@Component({
  selector: 'app-mobile-tris-select',
  templateUrl: './mobile-tris-select.component.html',
  styleUrls: ['./mobile-tris-select.component.scss']
})
export class MobileTrisSelectComponent extends AbstractDefaultComponent implements OnInit {
    @Input() showButtonEdit: boolean;
    @Input() userAmministrazioneRolesFiltered: AuthorityType[];
    @ViewChild('trisSelectDrawer') trisSelectDrawer: FuseDrawerComponent;
    config: AppConfig;
    cicloCorsoRuolo: CicloCorsoRuoloInterface;
    @Input() isAreaAdministrator!: boolean;
    cicloAmmSelected: number;
    @Output() openAdministrationRoleInfoDialog = new EventEmitter<AuthorityType>();

  constructor(public appInitService: AppInitService) {
      super();
      this.appInitService.cicloCorsoRuoloSelected$.subscribe(value => this.cicloCorsoRuolo= value)
      this.appInitService.cicloAmmSelected$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
          this.cicloAmmSelected = val;
      });
  }

  ngOnInit(): void {
  }

    openModalModificaCicloCorsoRuolo(): void {
      this.appInitService.openModalModificaCicloCorsoRuolo();
    }
    openModalModificaCicloAmm(): void {
        this.appInitService.openModalModificaCicloAmm();
    }

}
