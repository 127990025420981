import {FormComponent} from '../form/form.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {get, isEmpty} from 'lodash';
import {FormGroup} from '@angular/forms';
import {FormModuleService} from '../form/form-module.service';
import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {GenericComponentDialogConfig} from 'app/layout/common/generic-components/generic-components.interface';
import {BehaviorSubject} from "rxjs";
import {fuseAnimations} from "../../../../../@fuse/animations";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {SnackbarTypes} from "../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {TranslocoService} from "@ngneat/transloco";


@Component({
    selector: 'app-generic-dialog',
    templateUrl: './generic-dialog.component.html',
    styleUrls: ['./generic-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class GenericDialogComponent implements OnInit {

    dialogForm = new FormGroup({});
    formExist = false;
    @ViewChild(FormComponent) formComponent: FormComponent;
    alert$: BehaviorSubject<string> = new BehaviorSubject('');
    touched: boolean;

    constructor(public formService: FormModuleService,
                @Inject(MAT_DIALOG_DATA) public data: GenericComponentDialogConfig,
                private fuseConfirmationService: FuseConfirmationService,
                private _translocoService: TranslocoService,
                public dialogRef: MatDialogRef<GenericDialogComponent>) {
    }

    ngOnInit(): void {
        this.formExist = !!this.data && !isEmpty(this.data.formConfig);

    }


    saveForm() {
        const formIsValid = this.formComponent.formGroup.valid;
        if (formIsValid) {
            this.alert$.next('')
            this.data.actions.confirm.function(this.formComponent.formGroup, this.dialogRef, this.alert$)
        } else {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
            this.touched = true
            this.formComponent.formGroup.markAllAsTouched();
        }

    }

    saveForm2() {

        const formIsValid = this.formComponent.formGroup.valid;
        if (formIsValid) {
            this.alert$.next('')
            this.data.actions.confirm2.function(this.formComponent.formGroup, this.dialogRef, this.alert$)
        } else {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
            this.touched = true
            this.formComponent.formGroup.markAllAsTouched();
        }

    }

}


