/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di un attività extra piano di studi. Valori previsti: *INSERITA*, *RICHIESTA_SUPERAMENTO*, *RICHIESTA_SUPERAMENTO_RIFIUTATA*, *APPROVATA*
 */
export type AttivitaExtraPianoDiStudiStatus = 'INSERITA' | 'DA_APPROVARE' | 'ATTESTATO_PRESENTATO' | 'RIFIUTATA' | 'APPROVATA';

export const AttivitaExtraPianoDiStudiStatus = {
    INSERITA: 'INSERITA' as AttivitaExtraPianoDiStudiStatus,
    DAAPPROVARE: 'DA_APPROVARE' as AttivitaExtraPianoDiStudiStatus,
    ATTESTATOPRESENTATO: 'ATTESTATO_PRESENTATO' as AttivitaExtraPianoDiStudiStatus,
    RIFIUTATA: 'RIFIUTATA' as AttivitaExtraPianoDiStudiStatus,
    APPROVATA: 'APPROVATA' as AttivitaExtraPianoDiStudiStatus
};