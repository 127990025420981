<ng-container *transloco="let t ">
    <app-dialog-wrap [data]="data">

        <div class="flex flex-col w-full pb-6 p-6">
            <ng-container *transloco="let t">
                <fuse-alert
                    class="w-full mb-6"
                    [showIcon]="true"
                    [appearance]="'outline'"
                >{{t('common.message_tripla')}}</fuse-alert>
                <div class="items-center pt-2 flex flex-col gap-3">


                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>{{t('cycle_doctorate.number_cycle')}}</mat-label>
                        <mat-select [formControl]="cicloFormControl" [placeholder]="t('cycle_doctorate.number_cycle')">
                            <mat-option *ngFor="let ciclo of listaCicliCorsiRuoliGroupByCiclo | keyvalue"
                                        [value]="ciclo.key">
                                {{ciclo.key}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="cicloFormControl.hasError('required')">
                            {{t('form.required')}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full " appearance="outline" >
                        <mat-label>{{t('common.course')}}</mat-label>
                        <mat-select [formControl]="corsoStudiFormControl" [placeholder]="t('common.course')">
                            <mat-option *ngFor="let corso of corsoStudi" [value]="corso.codiceEsse3">
                                {{(corso.codiceEsse3 || '')+ ( (corso.cicliCorsiDiStudi[0]?.denominazione)? ' - ' : '')+( corso.cicliCorsiDiStudi[0]?.denominazione || '')}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="corsoStudiFormControl.hasError('required')">
                            {{t('form.required')}}
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field class="w-full " appearance="outline">
                        <mat-label>{{t('auth.role')}}</mat-label>
                        <mat-select [formControl]="ruoloFormControl" [placeholder]="t('auth.role')">
                            <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo">
                                {{ruolo | removeUnderscores}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="ruoloFormControl.hasError('required')">
                            {{t('form.required')}}
                        </mat-error>
                    </mat-form-field>

                </div>
            </ng-container>
        </div>
    </app-dialog-wrap>

    <!-- Actions -->
    <ng-container>
        <div
            class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

            <!-- Cancel -->

            <button color="accent" mat-flat-button [matDialogClose]="'cancelled'"
                    *ngIf="showAnnullaButton">
                {{t('dialog.cancel')}}
            </button>

            <button color="accent" mat-flat-button (click)=" logout()" *ngIf="!showAnnullaButton" >
                {{t('dialog.logout')}}
            </button>

            <!-- Confirm -->

            <button mat-flat-button
                    (click)=" applica()"
                    [color]="'primary'"
                    [disabled]="checkModificaForm"
            >
                      <span>
                    {{t('dialog.confirm')}}
                                    </span>
                <!-- <mat-progress-spinner
                     style="margin-left: 5px"
                     [diameter]="24"
                     [mode]="'indeterminate'"></mat-progress-spinner>-->
            </button>


        </div>
    </ng-container>

</ng-container>
