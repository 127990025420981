import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {CUSTOM_DATE_FORMAT} from "./shared/costants/app-constants";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginatorI18n} from "./shared/mat-paginator-i18n/mat-paginator-i18n";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {RefreshTokenInterceptor} from "./core/auth/refresh-token.interceptor";
import {ApiModule, Configuration} from "../api-clients/generated/services";
import {LocalStorageService} from "./shared/service/local-storage.service";
import {environment} from "../environments/environment";
import {AppInitService} from "./shared/service/app-init.service";
import {Translation, TranslocoModule, TranslocoService} from "@ngneat/transloco";
import {HeaderModule} from "./shared/components/header/header.module";
import {LoadingScreenModule} from "./layout/common/loading-screen/loading-screen.module";
import {CustomErrorHandler} from './shared/custom-error-handler/custom-error-handler';
import {HttpErrorInterceptor} from "./http-error-interceptor/http-error.interceptor";
import {PrivacyComponent} from "./modules/landing/privacy/privacy.component";
import {TermsAndConditionsComponent} from "./modules/landing/terms-and-conditions/terms-and-conditions.component";
import {TestoPrivacyModule} from "./shared/components/testo-privacy/testo-privacy.module";
import {
    TestoTerminiECondizioniModule
} from "./shared/components/testo-termini-e-condizioni/testo-termini-e-condizioni.module";
import {MatIconModule} from "@angular/material/icon";
import {NoopScrollStrategy} from "@angular/cdk/overlay";
import {MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {DialogShowDeliberaModule} from "./shared/components/dialog-show-delibera/dialog-show-delibera.module";

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
        PrivacyComponent,
        TermsAndConditionsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        ApiModule,
        MatDatepickerModule,
        MatNativeDateModule,
        HeaderModule,
        LoadingScreenModule,
        TranslocoModule,

        //privacy and terms modules
        TestoPrivacyModule,
        TestoTerminiECondizioniModule,
        MatIconModule,


        DialogShowDeliberaModule

    ],
    providers: [
        MatDatepickerModule,
        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT},
        {provide: MAT_DATE_LOCALE, useValue: 'it'},
        {provide: MatPaginatorIntl, useClass: MatPaginatorI18n},
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {scrollStrategy: new NoopScrollStrategy()}},
        /*{
            // THE REFRESH TOKEN INTERCEPTOR IS ALREADY PROVIDED BY THE CORE MODULE -> AUTH MODULE
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },*/
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: Configuration,
            useFactory: (localStorageService: LocalStorageService) => {
                return new Configuration(
                    {
                        basePath: environment.servicesUrl,
                        accessToken: (): string => localStorageService.getAccessToken(),
                    });
            },
            deps: [LocalStorageService],
            multi: false,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitService: AppInitService) => () => appInitService.init(), // useFactory è usato perché initializeApp è una funzione e non una classe
            deps: [AppInitService],
            multi: true
        },
        {provide: ErrorHandler, useClass: CustomErrorHandler},

        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule
{
}
