<ng-container *transloco="let t">
    <div class="flex flex-col justify-start items-center h-full m-2 lg:m-6 gap-4" style="    word-break: break-word;">

        <div class="flex flex-col justify-center items-center gap-3">
            <img
                class="dark:hidden w-52"
                src="assets/images/logo/logo_colori_1282_377.png"
                alt="Logo image">
        </div>
        <div class="flex flex-row w-full">
            <div class="w-full flex flex-row items-start pl-6 pr-6">
                <!-- Icon -->
                <div
                    class="flex flex-0 items-center justify-center hidden lg:flex w-10 h-10 sm:mr-4 rounded-full text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500">
                    <mat-icon
                        class="text-current"
                        [svgIcon]="'mat_solid:policy'"></mat-icon>
                </div>


                <ng-container>
                    <div
                        class="text-xl leading-6 font-medium lg:mt-2 lg:mb-2 lg:mr-4 "
                        [innerHTML]="t('privacy_and_condition.privacy') | uppercase"></div>
                </ng-container>
            </div>
        </div>

        <app-testo-privacy></app-testo-privacy>
    </div>
</ng-container>
