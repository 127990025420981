<ng-container *transloco="let t ">
    <div class="relative flex flex-col w-full h-full pr-2 pl-2">

        <!-- Dismiss button -->
        <ng-container *ngIf="data?.close">
            <div class="absolute top-0 right-0 pt-4 pr-4">
                <button
                    mat-icon-button
                    (click)="close$.emit()">
                    <mat-icon
                        class="text-secondary"
                        [svgIcon]="'heroicons_outline:x'"></mat-icon>
                </button>
            </div>
        </ng-container>
        <!-- Content -->
        <div class="flex flex-col sm:flex-row flex-auto items-center justify-center sm:items-start lg:pb-6 lg:pt-6">


            <ng-container>
                <div
                    class="flex flex-col items-center  mt-4 sm:mt-0 space-y-1 text-center sm:text-left w-full"
                    style="align-self: center">

                    <div class="flex flex-row w-full">
                        <div class=" flex flex-row items-start pl-6 pr-6">
                        <!-- Icon -->
                        <ng-container *ngIf="data?.icon?.name">
                            <div
                                class="flex flex-0 items-center justify-center hidden lg:flex w-10 h-10 sm:mr-4 rounded-full"
                                [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data.icon.color === 'primary',
                            'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data.icon.color === 'accent',
                            'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data.icon.color === 'warn',
                            'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data.icon.color === 'basic',
                            'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data.icon.color === 'info',
                            'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data.icon.color === 'success',
                            'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data.icon.color === 'warning',
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon.color === 'error'
                            }">
                                <mat-icon
                                    class="text-current"
                                    [svgIcon]="data.icon.name"></mat-icon>
                            </div>
                        </ng-container>

                        <!-- Title -->
                        <ng-container>
                            <div
                                class="text-xl leading-6 font-medium lg:mt-2 lg:mb-2 lg:mr-4 line-clamp-2"
                                [innerHTML]="(t(data.title) || t('dialog.attention')) | uppercase"></div>
                        </ng-container>

                        </div>

                    </div>

                    <ng-container *ngIf="message">
                        <div
                            class="text-secondary list-disc	 font-semibold px-6 pt-3 text-left w-full"
                            [innerHTML]="message "></div>
                    </ng-container>
                    <div matDialogContent="" class="w-full">
                        <ng-content></ng-content>
                    </div>

                </div>
            </ng-container>

        </div>


    </div>

</ng-container>
