<ng-container *transloco="let t">
    <app-dialog-wrap [data]="data">

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full min-w-0">

            <div class="flex flex-col flex-auto p-6 m-2 bg-card shadow rounded-2xl overflow-hidden cursor-pointer click"
                 (click)="closeDialog(AreaSeleztione.AMMINISTRAZIONE)"
            >
                <div class="flex flex-col items-center mt-2">
                    <div class="font-bold tracking-tight leading-none "><mat-icon class="icon-size-12">admin_panel_settings</mat-icon></div>
                    <div class="text-lg font-medium ">{{t('choose_area.administrator')}}</div>
                </div>
            </div>
            <div class="flex flex-col flex-auto p-6 m-2 bg-card shadow rounded-2xl overflow-hidden cursor-pointer click"
            (click)="closeDialog(AreaSeleztione.GESTIONALE)"
            >
                <div class="flex flex-col items-center mt-2">
                    <div class="font-bold tracking-tight leading-none "><mat-icon class="icon-size-12">school</mat-icon></div>
                    <div class="text-lg font-medium ">{{t('choose_area.management')}}</div>
                </div>
            </div>
        </div>


    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <button color="accent" mat-flat-button (click)=" logout()" >
            {{t('dialog.logout')}}
        </button>
    </div>

</ng-container>
