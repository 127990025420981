import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DeliberaInfoView, DelibereService, GetDeliberaDTO} from "../../../../api-clients/generated/services";
import {DialogWrapI} from "../../../layout/common/dialog-wrap/dialog-wrap.component";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";
import moment from "moment";
import {finalize, takeUntil} from "rxjs";
import {makeFilename, openFileInBlankWindow} from "../../utils/utils";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {AppInitService} from "../../service/app-init.service";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {HttpResponse} from "@angular/common/http";

export interface DialogShowDeliberaI extends DialogWrapI {
    subtitle?: any;
    delibera: DeliberaInfoView;
    codiceCorsoDiStudiEsse3?: string; //in amministrazione bisogna sempre inserirlo
}

@Component({
    selector: 'app-dialog-show-delibera',
    templateUrl: './dialog-show-delibera.component.html',
    styleUrls: ['./dialog-show-delibera.component.scss']
})
export class DialogShowDeliberaComponent extends AbstractDefaultComponent implements OnInit{
    approvalDate: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogShowDeliberaI,
                private appInitService: AppInitService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private delibereService: DelibereService,
                public dialogRef: MatDialogRef<DialogShowDeliberaComponent>,
    ) {
        super();
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.data = {
            ...this.data,
            title: get(translation, 'common.delibera') + (this.data?.subtitle ?  ' - ' + this.data.subtitle: ''),
            icon: {
                name: 'mat_outline:visibility',
                color: 'basic'
            },
            close: true
        };
    }

    ngOnInit(): void {
        this.approvalDate = this.data?.delibera?.data ? moment(this.data.delibera.data, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    }


    closeDialog() : void {
        this.dialogRef.close();
    }


    downloadAttachment(): void {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (this.appInitService.isAreaAdministrator && this.data.codiceCorsoDiStudiEsse3) {
            this.appInitService.setCodiceCorsoStudiAmm(this.data.codiceCorsoDiStudiEsse3)
        }

        if(this.appInitService.isAreaAdministrator && !this.data.codiceCorsoDiStudiEsse3){
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'budget.file_download_error', null),
                type: SnackbarTypes.Error,
            });
        } else {
            const body: GetDeliberaDTO = {
                nomeFile: this.data.delibera?.url,
                numeroDelibera: this.data?.delibera?.numero
            };
            this.fuseConfirmationService.showLoader();
            this.delibereService.downloadDelibera(body, 'response').pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                    if (this.appInitService.isAreaAdministrator) {
                        this.appInitService.cleanCorsoAmm();
                    }
                    this.fuseConfirmationService.hideLoader();
                })
            ).subscribe({
                next: (fileResponse: HttpResponse<Blob>) => {
                    const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                    const filenameToDownload = makeFilename(fileName);
                    openFileInBlankWindow(fileResponse?.body, filenameToDownload);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_download_error', null),
                        error: err, type: SnackbarTypes.Error,
                    });
                    console.log(err);
                }
            });
        }

    }

    downloadNotaTrasmissione(): void {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (this.appInitService.isAreaAdministrator && this.data.codiceCorsoDiStudiEsse3) {
            this.appInitService.setCodiceCorsoStudiAmm(this.data.codiceCorsoDiStudiEsse3)
        }

        if(this.appInitService.isAreaAdministrator && !this.data.codiceCorsoDiStudiEsse3){
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'budget.file_download_error', null),
                type: SnackbarTypes.Error,
            });
        } else {
            const body: GetDeliberaDTO = {
                nomeFile: this.data.delibera?.urlNotaDiTrasmissione,
                numeroDelibera: this.data?.delibera?.numero
            };
            this.fuseConfirmationService.showLoader();
            this.delibereService.downloadDelibera(body, 'response').pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                    if (this.appInitService.isAreaAdministrator) {
                        this.appInitService.cleanCorsoAmm();
                    }
                    this.fuseConfirmationService.hideLoader();
                })
            ).subscribe({
                next: (fileResponse: HttpResponse<Blob>) => {
                    const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                    openFileInBlankWindow(fileResponse?.body, fileName);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_download_error', null),
                        error: err, type: SnackbarTypes.Error,
                    });
                    console.log(err);
                }
            });
        }

    }


}

