/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di modifica ad un corso/attività di un piano di studi. Valori previsti: *AGGIUNTA*, *RIMOZIONE*
 */
export type TipoModificaCorsoPianoDiStudi = 'NESSUNA_MODIFICA' | 'AGGIUNTA' | 'RIMOZIONE';

export const TipoModificaCorsoPianoDiStudi = {
    NESSUNAMODIFICA: 'NESSUNA_MODIFICA' as TipoModificaCorsoPianoDiStudi,
    AGGIUNTA: 'AGGIUNTA' as TipoModificaCorsoPianoDiStudi,
    RIMOZIONE: 'RIMOZIONE' as TipoModificaCorsoPianoDiStudi
};