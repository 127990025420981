export * from './ateneo.service';
import { AteneoService } from './ateneo.service';
export * from './audit.service';
import { AuditService } from './audit.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './cicli.service';
import { CicliService } from './cicli.service';
export * from './cicliCorsiDiStudi.service';
import { CicliCorsiDiStudiService } from './cicliCorsiDiStudi.service';
export * from './delibere.service';
import { DelibereService } from './delibere.service';
export * from './dottorandiCressiWs.service';
import { DottorandiCressiWsService } from './dottorandiCressiWs.service';
export * from './import.service';
import { ImportService } from './import.service';
export * from './info.service';
import { InfoService } from './info.service';
export * from './media.service';
import { MediaService } from './media.service';
export * from './missioni.service';
import { MissioniService } from './missioni.service';
export * from './offerteFormative.service';
import { OfferteFormativeService } from './offerteFormative.service';
export * from './operazioniMassive.service';
import { OperazioniMassiveService } from './operazioniMassive.service';
export * from './periodiDiMobilita.service';
import { PeriodiDiMobilitaService } from './periodiDiMobilita.service';
export * from './pianiDiStudi.service';
import { PianiDiStudiService } from './pianiDiStudi.service';
export * from './questionari.service';
import { QuestionariService } from './questionari.service';
export * from './stats.service';
import { StatsService } from './stats.service';
export * from './studentiCiclo.service';
import { StudentiCicloService } from './studentiCiclo.service';
export * from './templateDelibere.service';
import { TemplateDelibereService } from './templateDelibere.service';
export * from './tickets.service';
import { TicketsService } from './tickets.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './utilities.service';
import { UtilitiesService } from './utilities.service';
export const APIS = [AteneoService, AuditService, AuthService, CicliService, CicliCorsiDiStudiService, DelibereService, DottorandiCressiWsService, ImportService, InfoService, MediaService, MissioniService, OfferteFormativeService, OperazioniMassiveService, PeriodiDiMobilitaService, PianiDiStudiService, QuestionariService, StatsService, StudentiCicloService, TemplateDelibereService, TicketsService, UsersService, UtilitiesService];
