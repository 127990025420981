import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthService} from 'app/core/auth/auth.service';
import {RefreshTokenInterceptor} from './refresh-token.interceptor';

@NgModule({
    imports  : [
        HttpClientModule
    ],
    providers: [
        AuthService,
        {
            provide : HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi   : true
        },
    ]
})
export class AuthModule
{
}
